import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const toastSubject = new Subject();

export const defaultId = 'default-toast';

export const ToastService = {
  onToast,
  success,
  error,
  info,
  warn,
  toast,
  clear
};

export const ToastType = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning'
}

// Enable subscribing to alerts observable
function onToast(id = defaultId) {
  return toastSubject.asObservable()
    .pipe(
      filter(x => x && x.id === id)
    );
}

// Core toast method
function toast (toast) {
  toast.id = toast.id || defaultId;
  toastSubject.next(toast);
}

// Convenience methods

function success(options) {
  toast({ ...options, type: ToastType.success, title: options.title || 'Success', subtitle: options.subtitle || '', message: options.message || '' });
}

function error(options) {
  toast({ ...options, type: ToastType.error, title: options.title || 'Error', subtitle: options.subtitle || '', message: options.message || '' });
}

function info(options) {
  toast({ ...options, type: ToastType.info, title: options.title || 'Info', subtitle: options.subtitle || '', message: options.message || '' });
}

function warn(options) {
  toast({ ...options, type: ToastType.warning, title: options.title || 'Warning', subtitle: options.subtitle || '', message: options.message || '' });
}

// Clear alerts
function clear(id = defaultId) {
  toastSubject.next({ id });
}
