import styled from 'styled-components';
import { responsive } from '../mixins/responsive';

export const ContactFormContent = styled.form`

    ${responsive.small_mobile.standard`
        background-color: whitesmoke;
        padding: 20px;
        border-radius: 10px;
    `};
`;

export const ContactFormConditionsContent = styled.div`
`;