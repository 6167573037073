import React from 'react';
import { ContentFloat, InfoBlockBgLinePosition } from '../../types';

/** HOME PAGE HERO SLIDES **/

export const heroSlideOne = {
  id: 'slide_1',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'Welcome',
  description: 'If you make smart decisions and invest in the right places, you can reduce the risk factor, increase the reward factor, and generate meaningful returns. We are here to make real estate investments confidently.',
  buttonLabel: 'About Us',
  imgStart: false,
  img: 'iStock-1161371668.jpg',
  imgOverlay: true,
  imgOverlayTransparency: 0.2,
  alt: '',
  dark: false,
  primary: true,
  darkText: false,
  transparent: true,
  inverted: false,
  to: 'about'
};

export const heroSlideTwo = {
  id: 'slide_2',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'Want To Sell Your House Fast?',
  description: 'We are real estate investment company that specializes in making the sale of your house in "as-is" condition fast, easy, with no commision and closing fees.',
  buttonLabel: 'Learn More',
  imgStart: false,
  img: 'iStock-1277551965.jpg',
  imgOverlay: true,
  imgOverlayTransparency: 0.2,
  alt: '',
  dark: false,
  primary: true,
  darkText: false,
  transparent: true,
  inverted: false,
  routeTo: process.env.REACT_APP_BUYING_HOUSE_DOMAIN_BASE_URL, 
  isExternalLink: true
};

export const heroSlideThree = {
  id: 'slide_3',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'Default On Your Mortgage Payments?',
  description: 'If you are facing a potential foreclosure, taking action to prevent damaging your credit and preserve your ability to obtain a future loan is critical. The lasting effects of foreclosure are avoidable and we can provide you some options to do so.',
  buttonLabel: 'Learn More',
  imgStart: false,
  img: 'iStock-847368022.jpg',
  imgOverlay: true,
  imgOverlayTransparency: 0.2,
  alt: '',
  dark: false,
  primary: true,
  darkText: false,
  transparent: true,
  inverted: false,
  routeTo: process.env.REACT_APP_AVOID_FORECLOSURE_DOMAIN_BASE_URL,
  isExternalLink: true
};

export const heroSlideFour = {
  id: 'slide_4',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'Lost Your House Due To Foreclosure?',
  description: 'You could have unclaimed funds that might be available to you. We are able to assist you with obtaining these funds.',
  buttonLabel: 'Learn More',
  imgStart: false,
  img: 'iStock-1159288215.jpg',
  imgOverlay: true,
  imgOverlayTransparency: 0.2,
  alt: '',
  dark: false,
  primary: true,
  darkText: false,
  transparent: true,
  inverted: false,
  routeTo: process.env.REACT_APP_SURPLUS_DOMAIN_BASE_URL,
  isExternalLink: true
};

/** END: HOME PAGE HERO SLIDES **/

export const heroObjOne = {
  id: 'hero',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'Smart investments',
  description: 'If you make smart decisions and invest in the right places, you can reduce the risk factor, increase the reward factor, and generate meaningful returns. We are here to make investments confidently in the right places.',
  buttonLabel: 'About Us',
  imgStart: false,
  img: null,
  alt: '',
  dark: false,
  primary: true,
  darkText: false,
  transparent: true,
  inverted: false,
  to: 'about'
};

export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'We Are Here To Help You',
  headline: 'About Us',
  description: 'Sophinex Investment is a family owned and operated company with a great vision, expertise and experience of doing smart investments in real estate. ||  We are a private company with a great vision, expertize, experience of doing smart investments and committed to provide our customers with outstanding customer service, personal attention and hard work. We would love to help you bring your dreams to reality and help to improve your finances. || As a family-owned and operated business, we work hard to build trust and strong relationships with all of our clients and partners. That is why we remain at the top of our game.',
  buttonLabel: 'Our Services',
  imgStart: false,
  img: require('../../images/hidden-person-bro.svg'),
  imgSourceHref: 'https://storyset.com/business',
  imgSourceTitle: 'Business illustrations by Storyset',
  imgSourceIntro: 'illustration by storyset',
  imgSourceColor: 'on-light',
  imgSourceLocation: 'relative',
  imgSourceAlignment: 'bottom-center',
  imgSourceVerticalOffset: 0,
  imgSourceShowIcon: false,
  alt: 'About Us',
  dark: true,
  primary: true,
  darkText: true,
  to: 'services'
};

/*export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Discover',
  headline: 'How It Works',
  description: 'Find out how to use our services.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/svg-2.svg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Team',
  headline: 'Creating an account is extremely easy',
  description: 'Get everything set up and ready in under 10 minutes. All you need to do is add your information and you are ready to go.',
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};*/

export const page404Obj = {
  id: 'notfound',
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: 'Oops',
  headline: 'Page not found',
  description: 'Sorry, the page you requested could not be found.',
  buttonLabel: 'Go Home',
  imgStart: false,
  img: require('../../images/404.jpg'),
  alt: 'Page 404',
  dark: true,
  primary: true,
  darkText: true,
  routeTo: '/'
};

export const heroObjSurplus = {
  id: 'surplusHero',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'Did You Go Through Forclosure Process Recently?',
  description: 'You could have unclaimed funds that might be available to you. We will assist you with obtaining these funds. We have attorneys in every state to get your claim submitted successfully. Contact us now to get help!',
  buttonLabel: 'Know more...',
  imgStart: false,
  img: null,
  bgImg: 'hl_finance.jpeg',
  alt: '',
  dark: false,
  primary: true,
  darkText: false,
  transparent: true,
  inverted: false,
  to: 'surplus-top'
};

export const homeObjFour = {
  id: 'more-info',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  contentFloat: ContentFloat.right,
  headerFloat: ContentFloat.left,
  shapePos: InfoBlockBgLinePosition.none,
  topLine: '',
  headline: 'Have Questions?',
  description: 'Do not hesitate to ask your questions or request more information about services we provide. We look forward to hearing from you!',
  buttonLabel: 'Contact Us',
  imgStart: true,
  img: require('../../images/contact-us-amico-business.svg'),
  imgSourceHref: 'https://storyset.com/business',
  imgSourceTitle: 'Business illustrations by Storyset',
  imgSourceIntro: 'illustration by storyset',
  imgSourceColor: 'on-light',
  imgSourceLocation: 'inline',
  imgSourceAlignment: 'bottom-center',
  imgSourceVerticalOffset: -32,
  imgSourceShowIcon: false,
  alt: 'Need More Info?',
  dark: false,
  primary: true,
  darkText: true,
  routeTo: '/contact-us'
};
