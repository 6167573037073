import React from 'react';
import { Button, RouteButton } from '../ButtonElements';
import {
  BlockWrapper,
  BlockBody,
  BlockBgShape,
  BlockImageWrapper,
  BlockImage,
  BlockContent,
  CustomSectionTitle,
  BtnWrap
} from './styles';
import { TextSize, ButtonVariant } from '../../types';
import ImageSourceInfo from '../ImageSourceInfo';
import BodyText from '../BodyText';

const InfoBlock = (props) => {
  const {
    children,
    lightBg,
    imgStart,
    topLine,
    lightText,
    textSize = TextSize.middle,
    headline,
    contentFloat,
    headerFloat,
    shapePos,
    description,
    buttonLabel,
    img,
    imgSourceHref,
    imgSourceTitle,
    imgSourceIntro,
    imgSourceColor,
    imgSourceLocation,
    imgSourceAlignment,
    imgSourceVerticalOffset,
    imgSourceShowIcon,
    bgImage,
    alt,
    id,
    variant = ButtonVariant.primary,
    darkText,
    dark,
    dark2,
    transparent,
    inverted,
    routeTo,
    to,
    highlightContent = false
  } = props;

  const img_1 = null;

  return (
    <BlockWrapper>
      <BlockBody>
        <BlockBgShape float={contentFloat || headerFloat} posType={shapePos} hasImage={!!img} />
        {
          (!!img) 
            ? <BlockImageWrapper float={contentFloat || headerFloat} bgImage={ bgImage } className='info-block-image-wrapper'>
                <BlockImage src={img} alt={alt} />
                <ImageSourceInfo 
                  href={imgSourceHref} 
                  title={imgSourceTitle} 
                  intro={imgSourceIntro} 
                  color={imgSourceColor} 
                  location={imgSourceLocation} 
                  alignment={imgSourceAlignment}
                  verticalOffset={imgSourceVerticalOffset}
                  showIcon={imgSourceShowIcon} />
              </BlockImageWrapper>
            : null
        }
        <BlockContent float={contentFloat || headerFloat} highlightContent={ highlightContent } hasImage={!!img} className='info-block-content-wrapper'>
          <CustomSectionTitle 
            topText={ topLine } 
            headerText={ headline } 
            textSize={ textSize } 
            darkText={ darkText }
            headerFloat={ headerFloat }
          >
            <BodyText text={ description }></BodyText>
          </CustomSectionTitle>
          {
            children 
              ? children 
              : !routeTo && !to ? null : <>
                  <BtnWrap float={contentFloat || headerFloat}>
                  {
                    to
                      ? <Button
                          to={ to || 'home' }
                          smooth={true}
                          duration={500}
                          spy={true}
                          exact='true'
                          offset={-80}
                          variant={ variant }
                          dark={dark ? 1 : 0}
                          dark2={dark2 ? 1 : 0}
                          className={ inverted ? 'inverted' : '' }
                        >
                          {buttonLabel}
                        </Button>
                      : <RouteButton
                          to={ routeTo || '/' }
                          smooth={true}
                          duration={500}
                          spy={true}
                          exact='true'
                          offset={-80}
                          variant={ variant }
                          dark={dark ? 1 : 0}
                          dark2={dark2 ? 1 : 0}
                          className={ inverted ? 'inverted' : '' }
                        >
                          {buttonLabel}
                        </RouteButton>
                  }
                  </BtnWrap>
                </>
          }
        </BlockContent>
      </BlockBody>
    </BlockWrapper>
  );
};

export default InfoBlock;
