import styled from 'styled-components';
import SectionTitle from '../SectionTitle';

export const ListContainer = styled.div`
  padding: 0;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ListWrapper = styled.div`
  max-width: 1290px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 32px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ListTitle = styled.h2`
    font-size: 3rem;
    height: 3rem;

    @media screen and (max-width: 480px) {
        font-size: 2.5rem;
    }
`;

export const ListSectionTitle = styled(SectionTitle)`
  padding-right: 0 important;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 50px;
  }
`;

export const ListTitleHorizLine = styled.div`
  width: 70px;
  height: 3px;
  background-color: red;
  content: '';
  margin: 30px 0 64px 0;
`;
