import styled from 'styled-components';

export const ItemContainer = styled.div`
  padding: 2rem;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;

  @media screen and (max-width: 1000px) {
    min-height: 330px;
  }

  @media screen and (max-width: 768px) {
    min-height: 280px;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
`;

export const ItemHeaderSidebar = styled.div`
  width: 60px;
  margin-right: 16px;
`;

export const ItemHeaderMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const ItemHeaderTitle = styled.h5`
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: rgba(0,0,0, .8);
`;

export const ItemHeaderSubTitle = styled.div`
  text-weight: normal;
  font-size: .85rem;
  color: rgba(0,0,0, .6);
`;

export const ItemMain = styled.div`
  display: flex;
  position: relative;
`;

export const ItemMainBody = styled.div`
  margin-bottom: 16px;
  text-align: justify;
  color: rgba(0,0,0, .8);
`;
