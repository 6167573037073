import React from 'react';
import {isFunction} from 'lodash';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons/lib';

import { NavLinkStyleTypes, ViewHeightVariant, ContentFloat } from '../../types'
import NavigationLinkWithContext from '../NavigationLinkWithContext';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarRouteButton,
  SideBtnWrap
} from './styles';

import { NavLogo } from '../DynamicNavbar/styles';
import Logo from '../Logo';
import { PageContentAlignment } from '../../pages/styles';
import ContainerWithBackground from '../ContainerWithBackground';

const DynamicSideNav = ({ baseUrl, layoutType, menuItems, menuButton, isOpen, setIsOpen, isBgDark = true, onClick }) => {

  const clickHandler = (e) => {
    setIsOpen(!isOpen);
    if (isFunction(onClick)) {
      onClick(e);
    }
  }

  return (
    <IconContext.Provider value={{ color: isBgDark ? '#ffffff' : '#6a6a6a' }}>
      <SidebarContainer isOpen={ isOpen } onClick={ clickHandler }>
        <ContainerWithBackground 
          bgImage={ '31179995_10.jpg' }
          bgColor={ '#e2e2e2' } 
          bgOverlayColor={ '#ffffff'} 
          bgOverlayTransparency={ 0.6 }
          heightVariant={ ViewHeightVariant.viewport }
        >
          <PageContentAlignment align={ ContentFloat.left } vAlign={ 'top' } noGap={ true }>
              <NavLogo className='flex-grow-1 py-4'>
                <Logo showNav={ true } isBgDark={ false } />
              </NavLogo>
              <Icon onClick={ clickHandler }>
                <CloseIcon />
              </Icon>  
          </PageContentAlignment>

          <PageContentAlignment align={ ContentFloat.center } vAlign={ 'center' } noGap={ true } style={{ height: 'calc(100% - 108px)' }}>
            
            <SidebarWrapper>
              {
                !!menuItems?.length 
                  ? <SidebarMenu>
                    {
                      menuItems.map((item, key) => (
                        <NavigationLinkWithContext 
                          { ...item} 
                          onClick={ clickHandler } 
                          style={ NavLinkStyleTypes.sidebar } 
                          alt={ item.title }
                          key={ key }
                        />
                      ))
                    }
                    </SidebarMenu>
                  : null
              }
              <SideBtnWrap>
              {
                menuButton 
                  ? <NavigationLinkWithContext { ...menuButton } onClick={ clickHandler } />
                  : null
              }
              </SideBtnWrap>
            </SidebarWrapper>
          </PageContentAlignment>
        </ContainerWithBackground>
      </SidebarContainer>
      </IconContext.Provider>
  );
};

DynamicSideNav.propTypes = {
  baseUrl: PropTypes.string,
  layoutType: PropTypes.string,
  menuItems: PropTypes.array,
  menuButton: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClick: PropTypes.func
};

DynamicSideNav.defaultProps = {
  baseUrl: '/', 
  layoutType: null, 
  menuItems: [],
  menuButton: null,
  isOpen: false, 
  setIsOpen: (isOpen) => {}, 
  onClick: (event) => {}
};

export default DynamicSideNav;
