import React from 'react';
import InfoSection from '../InfoSection'
import { page404SectionContent } from './content';

const Page404 = () => (
  <>
    <InfoSection { ...page404SectionContent } />
  </>
);

export default Page404;
