import React from 'react';
import Logo from '../Logo';
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormTitle,
  FormLabel,
  FormInput,
  FormButton,
  Text
} from './styles';

const SignIn = () => {
  return (
    <>
      <Container image={'hl_finance.jpeg'}>
        <FormWrap>
          <Icon to='/' title='Go to Home page' alt='Go to Home page link'>
            <Logo showNav={false} />
          </Icon>
          <FormContent>
            <Form action='#'>
              <FormTitle>Sign in to your account</FormTitle>
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput type='email' required />
              <FormLabel htmlFor='for'>Password</FormLabel>
              <FormInput type='password' required />
              <FormButton type='submit'>Continue</FormButton>
              <Text>Forgot password</Text>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default SignIn;
