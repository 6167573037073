import styled from 'styled-components';
import SectionTitle from '../SectionTitle';

export const ServicesContainer = styled.div`
  padding-bottom: 70px;
  min-height: 790px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ServicesHeaderWrapper = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .3)), to(rgba(0, 0, 0, .3))), url(${require(`../../images/DM168-ray-reits-image-adobe-stock.jpeg`)}) no-repeat;
  background: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url(${require(`../../images/DM168-ray-reits-image-adobe-stock.jpeg`)}) no-repeat;
  background-position-x: 0%, 0%;
  background-position-y: 0%, 0%;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  @media screen and (max-width: 480px) {
    height: 300px;
  }
`;

export const ServicesBodyWrapper = styled.div`
  margin-top: -75px;
`;

export const HeaderSectionTitle = styled(SectionTitle)`
  padding-right: 0 important;
`;

export const ServicesBody = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 24px;
  padding: 0 50px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*align-items: center;*/
  border-radius: 10px;
  min-height: 430px;
  padding: 30px;
  transition: all 0.2s ease-in-out;
  color: #000000;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  a {
    width: 150px;
    border-radius: 16px;
  }

  @media screen and (max-width: 1024px) {
    min-height: 380px;
  }

  @media screen and (max-width: 768px) {
    min-height: 340px;
  }

  @media screen and (max-width: 480px) {
    min-height: 340px;
  }
`;

export const ServiceNum = styled.h1`
  margin-bottom: 16px;
  font-size: 2.75rem;
  font-weight: 700;
  -webkit-transform: scaleY(1.1);
  transform: scaleY(1.1);
  color: ${({ color }) => !!color ? color : 'inherite' };
`;

export const ServiceTitle = styled.h2`
  font-size: 1.85rem;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const ServiceDescription = styled.p`
  font-size: .95rem;
  color: #5a5a5a;
  flex-grow: 1;
`;

export const ServiceCardFooter = styled.div`
  display: flex;
  justify-content: start;
`;
