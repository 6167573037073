import React, {createContext, useState} from 'react';
import { LayoutTypes } from '../types';

export const NavigationContext = createContext();

export const NavigationContextProvider = ({children}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [layoutType, setLayoutType] = useState(LayoutTypes.home);

	return (
		<NavigationContext.Provider value={{isOpen, setIsOpen, layoutType, setLayoutType}}>
			{children}
		</NavigationContext.Provider>
	)
}