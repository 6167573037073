import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';

export const Container = styled.div`
  color: #111;
  background: ${({ lightBg, transparent }) => (transparent ? 'transparent' : lightBg ? '#fff' : '#eee')};
  width: 100%;

  /*@media screen and (max-width: 768px) {
    padding: 50px 0;
  }*/

  ${respondTo.xxs.standard`
    padding: 50px 0;
  `};

  ${respondTo.xs.standard`
    padding: 50px 0;
  `};

  ${respondTo.lg.standard`
    padding: 0px;
  `};
`;

export const Wrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  display: grid;
  justify-content: center;
  font-size: 1.1rem;
  z-index: 1;
  height: ${({ height} ) => height || 'auto'};
  width: 100%;
  max-width: 1360px;

  ${respondTo.xxs.standard`
    & .info-block-image-wrapper {
      display: none;
    }
  `};

  ${respondTo.xs.standard`
    & .info-block-image-wrapper {
      display: none;
    }
  `};

  ${({ height} ) => respondTo.md.standard`
    height: ${height || 'auto'};

    & .info-block-image-wrapper {
      display: block;
    }
  `};
`;