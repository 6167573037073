import React, { Component } from 'react';
import emailjs from '@emailjs/browser';

import { ToastService } from '../../services/toast.service';

import { TextSize, NavLinkStyleTypes, TextTypes } from '../../types';
import { 
  COMPANY_FULL_NAME,
  COMPANY_PHONE
 } from '../../shared/globals';
import validate from '../../utils/validation';
import { ContactFormContent, ContactFormConditionsContent } from './styles';
import SectionTitle from '../SectionTitle';
import { SimpleButton } from '../ButtonElements';
import NavigationLinkWithContext from '../NavigationLinkWithContext';
import BodyText from '../BodyText';

const DEFAULT_STATE = {
  formIsValid: false,
  formControls: {
    firstName: {
      value: '',
      label: 'First name',
      valid: false,
      touched: false,
      validationRules: {
        required: true
      }
    },
    lastName: {
      value: '',
      label: 'Last name',
      valid: false,
      touched: false,
      validationRules: {
        required: true
      }
    },
    phone: {
      value: '',
      label: 'Phone number',
      placeholder: '(555) 555-5500 or 5555555500',
      valid: false,
      touched: false,
      validationRules: {
        required: true,
        phone: true
      }
    },
    email: {
      value: '',
      label: 'E-mail address',
      placeholder: 'name@example.com',
      valid: false,
      touched: false,
      validationRules: {
        required: true,
        email: true
      }
    },
    text: {
      value: '',
      label: 'Your message',
      placeholder: 'Your message',
      valid: false,
      touched: false,
      validationRules: {
        required: true,
        minLength: 10,
        maxLength: 1000
      }
    }
  }
};

class ContactForm extends Component {

  default_state = {...DEFAULT_STATE};
  messageMaxLength = 2500;
  isSending = false;

  constructor (props) {
    super(props);

    emailjs.init("HdhmBWfnOXaxOaAP2");

    this.messageMaxLength = this.props.messageMaxLength || 2500;
    this.default_state.formControls.text.validationRules.maxLength = this.messageMaxLength;

    this.state = {
      ...DEFAULT_STATE
    };
  }

  resetForm = () => {
    this.setState({
      ...DEFAULT_STATE
    });
  }

  formValuesChangeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedControls = {
      ...this.state.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    let formIsValid = true;

    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

    updatedControls[name] = updatedFormElement;

    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    this.setState({
      formControls: updatedControls,
      formIsValid: formIsValid
    });
  }

  formSubmitHandler = (e) => {
    e.preventDefault();

    this.isSending = true;

    emailjs.send("service_1hded7m", this.props.templateId || "general_template", {
      from_name: `${this.state.formControls.firstName.value} ${this.state.formControls.lastName.value}`,
      message: this.state.formControls.text.value,
      from_phone: this.state.formControls.phone.value,
      from_email: this.state.formControls.email.value
    }).then((result) => {
      this.isSending = false;
      ToastService.success({ message: 'Your message has been sent successfuly. We will contact you soon.', autohide: true, keepAfterRouteChange: true });
      this.resetForm();
    }, (error) => {
      this.isSending = false;
      ToastService.success({ message: 'Oops! Your message has not been sent. Please try again later.', autohide: true, keepAfterRouteChange: true });
    });;
  }

  render() {
    return (
      <div id='contact-form'>
        {
          !!this.props?.hideTitle 
            ? null
            : <SectionTitle headerText={ 'Let\'s Get in Touch' } textSize={ TextSize.middle }>
                <BodyText 
                  text={ 'We would love to help you to get an answer on any question you have.' } 
                  type={ TextTypes.p } 
                  size={ TextSize.middle }
                />
              </SectionTitle>
        }
        <ContactFormContent>

          <div class='mb-3'>
            <BodyText 
              text={ 'All fields marked with * are required.' }
              type={ TextTypes.p } 
              size={ TextSize.small }
            />
          </div>

          <div className={'row g-3'}>
            <div className={'col-sm-6'}>
              <label className={'form-label'} for='firstName'>
                { this.state.formControls.firstName.label } *
              </label>
              <input
                className={ 'form-control ' + (this.state.formControls.firstName.touched && !this.state.formControls.firstName.valid ? 'is-invalid' : '') }
                type='text'
                id='firstName'
                name='firstName'
                placeholder={ this.state.formControls.firstName.placeholder }
                value={ this.state.formControls.firstName.value }
                touched={ this.state.formControls.firstName.touched }
                valid={ this.state.formControls.firstName.valid }
                onChange={ this.formValuesChangeHandler }
              />
              <div className={ this.state.formControls.firstName.touched && !this.state.formControls.firstName.valid ? 'invalid-feedback' : 'valid-feedback' }>
                Enter your first name.
              </div>
            </div>

            <div className={'col-sm-6'}>
              <label className={'form-label'} for='lastName'>
                { this.state.formControls.lastName.label } *
              </label>
              <input
                className={ 'form-control ' + (this.state.formControls.lastName.touched && !this.state.formControls.lastName.valid ? 'is-invalid' : '') }
                type='text'
                id='lastName'
                name='lastName'
                placeholder={ this.state.formControls.lastName.placeholder }
                value={ this.state.formControls.lastName.value }
                touched={ this.state.formControls.lastName.touched }
                valid={ this.state.formControls.lastName.valid }
                onChange={ this.formValuesChangeHandler }
              />
              <div className={ this.state.formControls.lastName.touched && !this.state.formControls.lastName.valid ? 'invalid-feedback' : 'valid-feedback' }>
                Enter your last name.
              </div>
            </div>
          
            <div className={'col-sm-6'}>
              <label className={'form-label'} for='phone'>{ this.state.formControls.phone.label } *</label>
              <input
                className={ 'form-control ' + (this.state.formControls.phone.touched && !this.state.formControls.phone.valid ? 'is-invalid' : '') }
                type='text'
                id='phone'
                name='phone'
                placeholder={ this.state.formControls.phone.placeholder }
                value={ this.state.formControls.phone.value }
                touched={ this.state.formControls.phone.touched }
                valid={ this.state.formControls.phone.valid }
                onChange={ this.formValuesChangeHandler }
              />
              <div className={ this.state.formControls.phone.touched && !this.state.formControls.phone.valid ? 'invalid-feedback' : 'valid-feedback' }>
                Enter your phone number.
              </div>
            </div>
            <div className={'col-sm-6'}>
              <label className={'form-label'} for='email'>
                { this.state.formControls.email.label } *
              </label>
              <input
                className={ 'form-control ' + (this.state.formControls.email.touched && !this.state.formControls.email.valid ? 'is-invalid' : '') }
                type='text'
                id='email'
                name='email'
                placeholder={ this.state.formControls.email.placeholder }
                value={ this.state.formControls.email.value }
                touched={ this.state.formControls.email.touched }
                valid={ this.state.formControls.email.valid }
                onChange={ this.formValuesChangeHandler }
              />
              <div className={ this.state.formControls.email.touched && !this.state.formControls.email.valid ? 'invalid-feedback' : 'valid-feedback' }>
                Enter a valid e-mail address.
              </div>
            </div>

            <div className={'col-12'}>
              <label className={'form-label'} for='text'>
                { this.state.formControls.text.label } *
              </label>
              <textarea
                className={ 'form-control ' + (this.state.formControls.phone.touched && !this.state.formControls.phone.valid ? 'is-invalid' : '') }
                style={{ height: '200px' }}
                id='text'
                name='text'
                rows='10'
                placeholder={ this.state.formControls.text.placeholder }
                value={ this.state.formControls.text.value }
                touched={ this.state.formControls.text.touched }
                valid={ this.state.formControls.text.valid }
                onChange={ this.formValuesChangeHandler }
              />
              <span class='text-secondary'><small>{'Enter up to ' + this.messageMaxLength + ' characters.'}</small></span>
              <span className={ this.state.formControls.text.touched && !this.state.formControls.text.valid ? 'invalid-feedback' : 'valid-feedback' }>
                Enter your message.
              </span>
            </div>
          </div>

          <div class='mt-4'>
            <SimpleButton 
              className={ (!this.state.formIsValid ? 'disabled' : '') }
              dark primary 
              disabled={ !this.state.formIsValid || this.isSending }
              onClick={ this.formSubmitHandler }
            >
              &nbsp;&nbsp;&nbsp;{ this.props?.submitButtonTitle || 'Send' }&nbsp;&nbsp;&nbsp;
            </SimpleButton>
          </div>

          <ContactFormConditionsContent className="mt-4 text-secondary-">
            <BodyText 
              type={ TextTypes.div } 
              size={ TextSize.small }
              alignment='justify'
            >
              <span>By submitting your phone number you agree to receive text messages from {COMPANY_PHONE}, if you do not wish to receive text messages, reply STOP.</span><br/><br/>
              <span>By clicking the button, I agree to the <NavigationLinkWithContext isExternalLink={ true }  title={ 'Terms of Use' } path={ '/terms-of-use' } style={ NavLinkStyleTypes.normal } alt={ 'Terms of Use link' } /> and consent to receive calls, texts, voicemails (including marketing by autodialer and prerecorded and artificial voice) and emails at the phone number and email address I provided, including automated service alerts and marketing messages from and on behalf of {COMPANY_FULL_NAME} and their network of service providers. I understand that this consent applies even if I am on a do not call list, that consent is not required for purchase, and that I can call {COMPANY_PHONE} to proceed without consent. Msg/data rates may apply. If you do not wish to receive text messages, reply STOP.</span>
            </BodyText>
          </ContactFormConditionsContent>
        </ContactFormContent>
      </div>
    );
  }
};

export default ContactForm;
