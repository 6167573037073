import React from 'react';

import { COMPANY_DESCRIPTION, COMPANY_NAME } from '../shared/globals';
import { PageContentBlock, PageContentAlignment } from './styles';

import GeneralLayout from '../layouts/GeneralLayout';
import ContainerGrid from '../components/ContainerGrid';
import BgShapeBlock from '../components/BgShapeBlock';
import HeroSection from '../components/HeroSection';
import BlockSection from '../components/BlockSection';
import InfoBlock from '../components/InfoBlock';
import { LayoutTypes, TextSize, InfoBlockBgLinePosition, ContentFloat, SpacerSize, ButtonVariant, ContentVerticalFloat, NavLinkStyleTypes } from '../types';
import TestimonialListComponent from '../components/Testimonials';
import {
  homeObjOne,
  homeObjFour,
  heroSlideOne, 
  heroSlideTwo, 
  heroSlideThree, 
  heroSlideFour
} from '../components/InfoSection/Data';
import Services from '../components/Services';
import { getMainMenuItems } from '../utils/nav-menu';
import Spacer from '../components/Spacer';
import ContainerWithBackground from '../components/ContainerWithBackground';
import SectionTitle from '../components/SectionTitle';
import BodyText from '../components/BodyText';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';
import Image from '../components/Image';

const HomePage = () => {

  const navOptions = {
    ...getMainMenuItems(LayoutTypes.home),
    isBgDark: true,
    isSideBgDark: false
  };

  return (
    <GeneralLayout {...navOptions}>

      <HeroSection slides={ [heroSlideOne, heroSlideTwo, heroSlideThree, heroSlideFour] } />

      <Spacer id='about' size={ SpacerSize.lg } />

      <ContainerGrid className='d-none-'>
        <PageContentBlock>
          <div className='row g-4'>
            <div className='col-xs-12 col-md-12 col-lg-6'>
              <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.right } fullHeight={ true } baseZIndex={ 0 }>
                <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true }>
                  <Image 
                    img={ 'hidden-person-bro.svg' } 
                    style={{zIndex: '3'}} 
                    imgSourceColor={ 'on-light' }
                    imgSourceHref={ 'https://storyset.com/work' }
                    imgSourceTitle={ 'Work illustrations by Storyset' }
                    imgSourceIntro={ 'illustrations by storyset' }
                    imgSourceAlignment={ 'bottom-center' }
                    imgSourceVerticalOffset={ -16 }
                  />
                </PageContentAlignment>
              </BgShapeBlock>
            </div>
            <div className='col-xs-12 col-md-12 col-lg-6'>
              <PageContentAlignment align={ ContentFloat.left } vAlign={ ContentVerticalFloat.top } fullHeight={ true } noGap={ true }>
                <SectionTitle
                  topText={ 'WE ARE HERE TO HELP YOU' }
                  headerText={ 'About Us' }
                  textSize={ TextSize.large }
                  headerFloat={ ContentFloat.left }
                >
                  <BodyText type='div' alignment={ 'justify' }>
                    <p>{ COMPANY_DESCRIPTION}</p>
                    <ul>
                      <li>Want to sell your house quickly? Sell your house as-is without any fees at your convenience. Get an instant cash offer with { COMPANY_NAME }!</li>
                      <li>Facing foreclosure? Avoid it confidently with our proven strategy, at no cost to you and full credit protection.</li>
                      <li>Want to sell non-perfoming mortgage notes? Let us know and we will provide you an offer.</li>
                      <li>Do you believe that you are entitled to any surplus funds? We can help you to recover those funds.</li>
                    </ul>
                    <p><b>Most of our services are 100% free.</b> If there are any fees, we charge <b>NO upfront fees</b> and only get paid fees upon successful service completion. <b>So you bear no risk at all.</b></p>
                    <p>We work hard to build trust and strong relationships with all of our clients and partners. That is why we remain at the top of our game.</p>
                  </BodyText>
                  <Spacer size='xs'></Spacer>
                  <PageContentAlignment align={ ContentFloat.right } vAlign={ 'center' } noGap={ true }>
                    <NavigationLinkWithContext 
                      title={ 'Learn About Our Services' } 
                      path={ '/' } 
                      hash={ 'services' } 
                      isExternalLink={ false } 
                      style={ NavLinkStyleTypes.button }
                      variant={ ButtonVariant.primary }
                      dark={ false } 
                      alt={ 'Learn About Our Services' } />
                  </PageContentAlignment>
                  
                </SectionTitle>
              </PageContentAlignment>
            </div>
          </div>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer size={ SpacerSize.lg } />

      <BlockSection {...homeObjOne} textSize={ TextSize.small } className='d-none' />

      <Services />

      <Spacer />

      <ContainerGrid isFullWidth={ true } className='d-none-'>
        <ContainerWithBackground
          bgImage={ '31179995_10.jpg' }
          bgColor={ '#e2e2e2' } 
          bgOverlayColor={ '#000000'} 
          bgOverlayTransparency={ 0.3 }
          takeFullViewport={ false }
        >
          <ContainerGrid>
            <PageContentBlock>
              <Spacer id='testimonials' size={ SpacerSize.md } />
              <TestimonialListComponent headerText={ 'What Home Owners Say' } />
              <Spacer size={ SpacerSize.lg } />
            </PageContentBlock>
          </ContainerGrid>
        </ContainerWithBackground>
      </ContainerGrid>

      <Spacer />

      <ContainerGrid>
        <PageContentBlock>
          <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.center } fullHeight={ true } baseZIndex={ 0 }>
            <InfoBlock {...homeObjFour}></InfoBlock>
          </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer />

    </GeneralLayout>
  );
}

export default HomePage;
