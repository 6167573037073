import React from 'react';
import { BgShapeBlockWrapper, BgShape, BgShapeContent, BgShapeBlockContent } from './styles';
import { InfoBlockBgLinePosition, ContentFloat } from '../../types';

const BgShapeBlock = (props) => {

  const {
    children,
    className,
    shapePos = InfoBlockBgLinePosition.none,
    float = ContentFloat.left,
    baseZIndex = 0,
    fullHeight = false,
    addContentSideOffset = true,
    color,
    noGap = false
  } = props;

  return (
    <BgShapeBlockWrapper className={ className } fullHeight={ fullHeight } noGap={ noGap }>
        <BgShapeContent posType={shapePos} base_z_index={ baseZIndex }>
            <BgShape posType={shapePos} float={ float } color={ color } />
        </BgShapeContent>
        <BgShapeBlockContent posType={ shapePos } float={ float } addContentSideOffset={ addContentSideOffset } base_z_index={ baseZIndex }>
            { children }
        </BgShapeBlockContent>
    </BgShapeBlockWrapper>
  );
};

export default BgShapeBlock;
