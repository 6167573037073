import styled from 'styled-components';
import { responsive } from '../mixins/responsive';
import BodyText from '../BodyText';

export const ContactTypesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ContactTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
  margin-top: 40px;
`;

export const ContactTypeIcon = styled.span`
  display: flex;
  align-items: center;
  color: #999;
  margin-bottom: 20px;

  ${responsive.small_mobile.standard`
    width: 35px;
    height:35px 
  `};
`;

export const ContactTypeTitle = styled(BodyText)`
  text-align: center;
  margin-bottom: 16px;
`;

export const ContactTypeText = styled.p`
  text-align: center;
  font-size: 1.1rem;
  color: #111;
`;
