import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { NavLinkStyleTypes } from '../../types';
import {
  NavbarLink,
  NavbarRouteLink,
  NavbarAnchorLink,
  SidebarRouteLink,
  SidebarAnchorLink,
  FooterLink,
  FooterRouteLink,
  FooterAnchorLink,
  NormalLink,
  NormalRouteLink,
  NormalAnchorLink
} from './styles';
import { SimpleButtonLink, RouteButton, Button } from '../ButtonElements';

export const scrollNavOffset = -80;

class NavigationLink extends React.Component {

  constructor(props) {
    super(props);
  }

  getLinkComponent() {
    const { style, isScrollNav, isExternalLink, hash } = this.props;

    if (style === NavLinkStyleTypes.navbar) {
      return (isScrollNav && !isEmpty(hash) && !isExternalLink) ? NavbarAnchorLink : (!isExternalLink) ? NavbarRouteLink : NavbarLink;
    } else if (style === NavLinkStyleTypes.sidebar) {
      return (isScrollNav && !isEmpty(hash) && !isExternalLink) ? SidebarAnchorLink : SidebarRouteLink;
    } else if (style === NavLinkStyleTypes.footer) {
      return (isScrollNav && !isEmpty(hash) && !isExternalLink) ? FooterAnchorLink : (!isExternalLink) ? FooterRouteLink : FooterLink;
    } else if (style === NavLinkStyleTypes.button) {
      return (isScrollNav && !isEmpty(hash) && !isExternalLink) ? Button : (!isExternalLink) ? RouteButton : SimpleButtonLink;
    } else {
      return (isScrollNav && !isEmpty(hash) && !isExternalLink) ? NormalAnchorLink : (!isExternalLink) ? NormalRouteLink : NormalLink;
    }
  };

  getLinkProps() {
    let result =  {
      to: this.props.to,
      title: this.props.title,
      alt: this.props.alt,
      onClick: this.props.onClick,
      className: this.props.className,
      variant: this.props.variant,
      dark: this.props.dark
    };
    if (this.props.isExternalLink) {
      result = {
        ...result,
        href: (this.props.isScrollNav && !isEmpty(this.props.hash)) ? `${this.props.path}#${this.props.hash}` : this.props.to,
        target: '_blank'
      }
    }
    if (this.props.isScrollNav && !isEmpty(this.props.hash)) {
      result = {
        ...result,
        smooth: true,
        duration: 500,
        spy: true,
        exact: 'true',
        offset: scrollNavOffset
      }
    }
    return result;
  }

  onClickHandler(event) {

  }

  render() {
    const Link = this.getLinkComponent();
    const linkProps = this.getLinkProps();

    return (
      <Link { ...linkProps }>
        { this.props.title }
      </Link>
    );
  }
}

NavigationLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isScrollNav: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  style: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func
};

NavigationLink.defaultProps = {
  title: '',
  to: '/',
  isScrollNav: false,
  isExternalLink: false,
  style: NavLinkStyleTypes.normal,
  alt: '',
  onClick: (event) => {}
};

export default NavigationLink;