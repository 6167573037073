import React from 'react';

import {
  ContactTypesWrapper,
  ContactTypeContainer,
  ContactTypeIcon,
  ContactTypeTitle
} from './styles';

import { TextSize } from '../../types';

import { COMPANY_PHONE, COMPANY_FAX, COMPANY_EMAIL } from '../../shared/globals';

import BodyText from '../BodyText';

const ContactTypes = (props) => {

  const contactTypeSvgContent = {
    width: '50px',
    height: '50px',
    fill: '#999'
  };

  return (
    <ContactTypesWrapper className='row'>
      <ContactTypeContainer className='col col-12 col-sm-12 col-md-3 col-lg-3'>
        <ContactTypeIcon>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="svg-cd86" x="0px" y="0px" viewBox="0 0 384 384" xmlSpace="preserve" className="contact-type-svg-content" style={contactTypeSvgContent}>
            <g>
              <g>
                <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594    c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448    c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813    C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"></path>
              </g>
            </g>
          </svg>
        </ContactTypeIcon>
        <ContactTypeTitle text={ 'phone' } size={ 'middle'} weight={ 700 } color={ 'rgb(17, 17, 17)' } transform={ 'uppercase' } alignment={ 'center' } />
        <BodyText text={ COMPANY_PHONE } type='p' size={ TextSize.middle } color={ 'rgb(17, 17, 17)' } />
        <BodyText text={ '(toll free)' } type='p' size={ TextSize.small } color={ 'rgb(84 83 83)' } />
      </ContactTypeContainer>
      <ContactTypeContainer className='col col-12 col-sm-12 col-md-3 col-lg-3'>
        <ContactTypeIcon>
          <svg xmlns="http://www.w3.org/2000/svg" id="svg-f872" viewBox="0 0 512 512" className="contact-type-svg-content" style={contactTypeSvgContent}>
            <path d="m201 12.714v184.286h267v-184.286c0-7.022-5.692-12.714-12.714-12.714h-241.572c-7.022 0-12.714 5.692-12.714 12.714zm63.89 33.131h70.271c8.284 0 15 6.716 15 15s-6.716 15-15 15h-70.271c-8.284 0-15-6.716-15-15s6.715-15 15-15zm0 75.142h139.22c8.284 0 15 6.716 15 15s-6.716 15-15 15h-139.22c-8.284 0-15-6.716-15-15s6.715-15 15-15z"></path>
            <path d="m472 227h-275c-22.091 0-40 17.909-40 40v205c0 22.091 17.909 40 40 40h275c22.091 0 40-17.909 40-40v-205c0-22.091-17.909-40-40-40zm-207.5 217.5h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 120h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 120h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15z"></path>
            <path d="m87 227h-47c-22.091 0-40 17.909-40 40v205c0 22.091 17.909 40 40 40h47c22.091 0 40-17.909 40-40v-205c0-22.091-17.909-40-40-40z"></path>
          </svg>
        </ContactTypeIcon>
        <ContactTypeTitle text={ 'fax' } size={ 'middle'} weight={ 700 } color={ 'rgb(17, 17, 17)' } transform={ 'uppercase' } alignment={ 'center' } />
        <BodyText text={ COMPANY_FAX } type='p' size={ TextSize.middle } color={ 'rgb(17, 17, 17)' } />
        <br/>
      </ContactTypeContainer>
      <ContactTypeContainer className='col col-12 col-sm-12 col-md-3 col-lg-3'>
        <ContactTypeIcon>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="svg-d2c4" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve" className="contact-type-svg-content" style={contactTypeSvgContent}>
            <g>
              <g>
                <path d="M467,61H45c-6.927,0-13.412,1.703-19.279,4.51L255,294.789l51.389-49.387c0,0,0.004-0.005,0.005-0.007    c0.001-0.002,0.005-0.004,0.005-0.004L486.286,65.514C480.418,62.705,473.929,61,467,61z"></path>
              </g>
            </g>
            <g>
              <g>
                <path d="M507.496,86.728L338.213,256.002L507.49,425.279c2.807-5.867,4.51-12.352,4.51-19.279V106    C512,99.077,510.301,92.593,507.496,86.728z"></path>
              </g>
            </g>
            <g>
              <g>
                <path d="M4.51,86.721C1.703,92.588,0,99.073,0,106v300c0,6.923,1.701,13.409,4.506,19.274L173.789,256L4.51,86.721z"></path>
              </g>
            </g>
            <g>
              <g>
                <path d="M317.002,277.213l-51.396,49.393c-2.93,2.93-6.768,4.395-10.605,4.395s-7.676-1.465-10.605-4.395L195,277.211    L25.714,446.486C31.582,449.295,38.071,451,45,451h422c6.927,0,13.412-1.703,19.279-4.51L317.002,277.213z"></path>
              </g>
            </g>
            <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
          </svg>
        </ContactTypeIcon>
        <ContactTypeTitle text={ 'e-mail' } size={ 'middle'} weight={ 700 } color={ 'rgb(17, 17, 17)' } transform={ 'uppercase' } alignment={ 'center' } />
        <BodyText text={ COMPANY_EMAIL } type='p' size={ TextSize.middle } color={ 'rgb(17, 17, 17)' } />
        <br/>
      </ContactTypeContainer>
      
    </ContactTypesWrapper>
  );
};

export default ContactTypes;