export const LayoutTypes = Object.freeze({
    home: 'HomeLayout',
    main: 'MainLayout',
    auth: 'AuthLayout',
    subdom: 'SubDomainLayout'
});

export const NavLinkTypes = Object.freeze({
    normal: 'Normal',
    anchor: 'Anchor',
    route: 'Route'
});

export const NavLinkStyleTypes = Object.freeze({
    navbar: 'Navbar',
    sidebar: 'Sidebar',
    normal: 'Normal',
    footer: 'Footer',
    button: 'Button'
});

export const SpacerSize = Object.freeze({
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg'
});

export const TextSize = Object.freeze({
    xs: 'XS',
    small: 'Small',
    middle: 'Middle',
    large: 'Large'
});

export const TextSizes = Object.freeze({
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    xxl: 'xxl'
});

export const TextTypes = Object.freeze({
    p: 'p',
    span: 'span',
    div: 'div',
    label: 'label'
});

export const HeaderTextTypes = Object.freeze({
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    h7: 'h7'
});

export const ContentFloat = Object.freeze({
    left: 'left',
    right: 'right',
    center: 'center',
    top: 'top',
    bottom: 'bottom'
});

export const ContentVerticalFloat = Object.freeze({
    top: 'top',
    center: 'center',
    bottom: 'bottom'
});

export const InfoBlockBgLinePosition = Object.freeze({
    side: 'side',               // vertical side
    middle: 'middle',           // vertical center
    half: 'half',               // vertical half
    sideBottom: 'side-bottom',  // horizontal left/right bottom
    top: 'top',                 // horizontal top
    center: 'center',           // horizontal center
    bottom: 'bottom',           // horizontal bottom
    none: 'none'
});

export const ButtonVariant = Object.freeze({
    primary: 'primary',
    secondary: 'secondary',
    success: 'success'
});

export const ViewHeightVariant = Object.freeze({
    viewport: 'viewport',
    full: 'full',
    auto: 'auto',
    custom: 'custom'
});

export const BoderShapeVariant = Object.freeze({
    left_top_right: 'left-top-right',
    top_right_bottom: 'top-right-bottom',
    right_bottom_left: 'right-bottom-left',
    bottom_left_top: 'bottom-left-top',
    left_top_right_bottom: 'left-top-right-bottom'
}); 