import { Link as LinkR } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  background: ${({ showNav }) => (!!showNav ? '#ffffff' : 'transparent')};
  height: ${({ showNav }) => (!!showNav ? '80px' : '100px')};
  margin-top: ${({ showNav }) => (!!showNav ? '-80px' : '-100px')};
  margin-left: ${({ showNav }) => (!!showNav ? '-10px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 30;
  box-shadow: ${({ showNav }) => (!!showNav ? '0 0px 10px 0 rgba(0,0,0,.3)' : 'none')};

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${({ showNav }) => (!!showNav ? '80px' : '100px')};
  z-index: 1;
  width: 100%;
  padding: ${({ showNav }) => (!!showNav ? '0' : '20px')} 14px 0 14px;
  max-width: 1360px;

  @media screen and (max-width: 374px) {
    padding: ${({ showNav }) => (!!showNav ? '0' : '20px')} 14px 0 0;
  }
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-weight: bold;
  text-decoration: none !important;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1260px) {
    display: block;
    position: absolute;
    top: ${({ showNav }) => (!!showNav ? '-5px' : '10px')};
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: inherit;
  }
`;

export const NavMenu = styled.ul`
  padding-right: 16px;
  display: ${({ hidden }) => (!!hidden ? 'none' : 'flex')};
  flex-grow: 1;
  justify-content: end;
  align-items: center;
  list-style: none;
  text-align: center;
  height: 100%;

  @media screen and (max-width: 1260px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  font-size: .9em;
  font-weight: 600;
  height: 100%;

  @media screen and (max-width: 1260px) {
    display: none;
  }
`;

export const NavStaticItem = styled.li`
  font-size: .9em;
  font-weight: 600;
  height: 100%;

  @media screen and (max-width: 1260px) {
    margin-right: 56px;
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-wieght: 600;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PhoneIcon = styled.span`
  fill: ${({ isBgDark }) => (!!isBgDark ? '#ffffff' : '#4473af')};
`;

export const PhoneNumber = styled.span`
  color: ${({ isBgDark }) => (!!isBgDark ? '#ffffff' : '#4473af')};
  padding-left: 10px;
`;

/*export const NavLinks = styled(LinkS)`
  color: ${({ showNav }) => (!!showNav ? '#2f2e2e' : 'transparent')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #dd3232;
  }
`;

export const NavRouteLinks = styled(LinkR)`
  color: ${({ showNav }) => (!!showNav ? '#2f2e2e' : 'transparent')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #dd3232;
  }
`;*/
