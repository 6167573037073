import { css } from 'styled-components';
/*
XS    0.6875/1.4545454545/400       12px  0.75rem
SM    0.875rem/1.4285714286/400     14px  0.875rem
MD    1.0625/1.4117647059/400       18px  1.125rem
LG    1.4375/1.3913043478/400       22px  1.375rem
XL    1.75/1.1428571429/400         26px  1.625rem
*/
export const fontSize = (size, base = 12) => `
  font-size: ${size}px; // older browsers fallback
  font-size: calc(${size / base} * 1rem);
`;

export const text = {
  text: (size = '1.125rem', weight = 400, lineHeight = 'inherit', alignment = 'left', color ='inherit', transform = 'none') => css`
    font-size: ${size} !important;
    font-weight: ${weight};
    line-height: ${lineHeight};
    text-align: ${alignment};
    color: ${color};
    text-transform: ${transform};
  `
};