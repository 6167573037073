import React from 'react';

import {
  ServicesContainer,
  ServicesHeaderWrapper,
  ServicesBodyWrapper,
  ServicesBody,
  ServicesCard,
  ServiceNum,
  ServiceTitle,
  ServiceDescription,
  ServiceCardFooter,
  HeaderSectionTitle
} from './styles';
import { TextSize, ContentFloat, NavLinkStyleTypes } from '../../types';

import NavigationLink from '../NavigationLink';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesHeaderWrapper>
        <HeaderSectionTitle 
          topText={ 'Services' } 
          headerText={ 'Our Services' } 
          headerFloat={ ContentFloat.center } 
          textSize={ TextSize.large } 
          darkText={ false } 
          isVertical={ true }
        />
      </ServicesHeaderWrapper>
      <ServicesBodyWrapper>
        <ServicesBody>
          <ServicesCard className='card-shadow'>
            <ServiceNum color={'#f48f86'}>01</ServiceNum>
            <ServiceTitle>Real Estate Management</ServiceTitle>
            <ServiceDescription>
              Buying and selling real estates using creative financing.
            </ServiceDescription>
            <ServiceCardFooter>
              <NavigationLink 
                title={ 'Learn More' } 
                to={ process.env.REACT_APP_BUYING_HOUSE_DOMAIN_BASE_URL } 
                style={ NavLinkStyleTypes.button } 
                isExternalLink={ true } 
                isScrollNav={ false } 
                className='inverted disabled-' 
                primary
                dark
                alt='Learn more' />
            </ServiceCardFooter>
          </ServicesCard>
          <ServicesCard className='card-shadow'>
            <ServiceNum color={'#ceb363'}>02</ServiceNum>
            <ServiceTitle>Mortgage Notes</ServiceTitle>
            <ServiceDescription>
              Buying and selling mortgage notes. Making non-performing notes work.
            </ServiceDescription>
            <ServiceCardFooter>
              <NavigationLink 
                title={ 'Learn More' } 
                to={ process.env.REACT_APP_NOTES_DOMAIN_BASE_URL } 
                style={ NavLinkStyleTypes.button } 
                isExternalLink={ true } 
                isScrollNav={ false } 
                className='inverted disabled-' 
                primary
                dark
                alt='Learn more' />
            </ServiceCardFooter>
          </ServicesCard>
          <ServicesCard className='card-shadow'>
            <ServiceNum color={'#93BC93'}>03</ServiceNum>
            <ServiceTitle>Foreclosure Avoidance</ServiceTitle>
            <ServiceDescription>
              Resolving pre-/foreclosure situation.
            </ServiceDescription>
            <ServiceCardFooter>
              <NavigationLink 
                title={ 'Learn More' } 
                to={ process.env.REACT_APP_AVOID_FORECLOSURE_DOMAIN_BASE_URL } 
                style={ NavLinkStyleTypes.button } 
                isExternalLink={ true } 
                isScrollNav={ false } 
                className='inverted disabled-' 
                primary
                dark
                alt='Learn more' />
            </ServiceCardFooter>
          </ServicesCard>
          <ServicesCard className='card-shadow'>
            <ServiceNum color={'#6382AA'}>04</ServiceNum>
            <ServiceTitle>Assets Recovery</ServiceTitle>
            <ServiceDescription>
              Helping to claim your surplus money from the state funds.
            </ServiceDescription>
            <ServiceCardFooter>
              <NavigationLink 
                title={ 'Learn More' } 
                to={ process.env.REACT_APP_SURPLUS_DOMAIN_BASE_URL } 
                style={ NavLinkStyleTypes.button } 
                isExternalLink={ true } 
                isScrollNav={ false } 
                className='inverted' 
                primary
                dark
                alt='Learn more' />
            </ServiceCardFooter>
          </ServicesCard>
        </ServicesBody>
      </ServicesBodyWrapper>
    </ServicesContainer>
  );
};

export default Services;
