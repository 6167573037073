import styled from 'styled-components';

const getTop = (location, alignment, verticalOffset) => {
  const value = 20 + (verticalOffset || 0);
  if (['top-left', 'top-center', 'top-right'].indexOf(alignment) > -1) {
    return location === 'inline' ? `${value}px` : `${value * -1}px`;
  }
  return 'unset';
}

const getLeft = (location, alignment) => {
  if (['top-left', 'bottom-left', 'top-center', 'bottom-center'].indexOf(alignment) > -1) {
    return '20px';
  }
  return 'unset';
}

const getRight = (location, alignment) => {
  if (['top-right', 'bottom-right'].indexOf(alignment) > -1) {
    return '20px';
  }
  return 'unset';
}

const getBottom = (location, alignment, verticalOffset) => {
  const value = 20 + (verticalOffset || 0);
  if (['bottom-left', 'bottom-center', 'bottom-right'].indexOf(alignment) > -1) {
    return location === 'inline' ? `${value}px` : `${value * -1}px`;
  }
  return 'unset';
}

const getJustifyContent = (alignment) => {
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'top-center':
    case 'bottom-center':
      return 'center';
    default:
      return 'flex-end';
  }
}

const getWidth = (alignment) => {
  if (['top-center', 'bottom-center'].indexOf(alignment) > -1) {
    return '100%';
  }
  return 'auto';
}

export const ImageSourceInfoLink = styled.a`
  display: flex;
  justify-content: ${({ alignment }) => getJustifyContent(alignment) };
  align-items: center;
  position: absolute;
  top: ${({ location, alignment, verticalOffset }) => getTop(location, alignment, verticalOffset) };
  left: ${({ location, alignment }) => getLeft(location, alignment) };
  bottom: ${({ location, alignment, verticalOffset }) => getBottom(location, alignment, verticalOffset) };
  right: ${({ location, alignment }) => getRight(location, alignment) };
  z-index: 10;
  color: ${({ color }) => color === 'on-dark' ? '#acacac' : '#848487' };
  width: ${({ alignment }) => getWidth(alignment) };

  &:hover {
    color: ${({ color }) => color === 'on-dark' ? '#fff' : '#616168' };
  }
`;

export const ImageSourceIntro = styled.span`
  font-size: 0.6rem;
  padding-right: 5px;
  align-self: center;
`;