import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';
import { responsive } from './mixins/responsive';
import { ButtonVariant } from '../types';

const getColors = (variant = ButtonVariant.primary, dark = false, hovered = false) => {
  if (hovered) {
    return {
      color: variant === ButtonVariant.success ? '#0b930b' : variant === ButtonVariant.secondary ? '#3c3c3c' : '#dd4132 !important',
      bgColor: variant === ButtonVariant.success ? '#edfaf0' : variant === ButtonVariant.secondary ? 'transparent' : '#dd413217',
      borderColor: `${ variant === ButtonVariant.success ? '#0b930b' : variant === ButtonVariant.secondary ? '#575858' : '#dd4132'} solid 2px;`
    }
  }
  return {
    color: '#ffffff',
    bgColor: variant === ButtonVariant.success ? '#0b930b' : variant === ButtonVariant.secondary ? '#575858' : '#dd4132',
    borderColor: `${ variant === ButtonVariant.success ? '#0b930b' : variant === ButtonVariant.secondary ? '#575858' : '#dd4132'} solid 2px;`
  }
}

const getInvertedColors = (variant = ButtonVariant.primary, dark = false, hovered = false) => {
  if (hovered) {
    return {
      color: '#ffffff',
      bgColor: variant == ButtonVariant.success ? '#0b930b' : variant == ButtonVariant.secondary ? '#575858' : '#dd4132',
      borderColor: `${
        dark && variant == ButtonVariant.success 
          ? '#0b930b' 
          : dark && variant == ButtonVariant.secondary 
            ? '#e3e1e1' 
            : dark && variant == ButtonVariant.primary 
              ? '#dd4132' 
              : !dark && variant == ButtonVariant.success 
                ? '#0b930b' 
                : !dark && variant == ButtonVariant.secondary 
                  ? '#575858' 
                  : '#dd4132'} solid 2px;`
    }
  }
  return {
    color: variant == ButtonVariant.success ? '#0b930b' : variant == ButtonVariant.secondary ? '#575858' : '#dd4132',
    bgColor: '#fcfcfc',
    borderColor: `${
      dark && variant == ButtonVariant.success 
        ? '#0b930b' 
        : dark && variant == ButtonVariant.secondary 
          ? '#e3e1e1' 
          : dark && variant == ButtonVariant.primary 
            ? '#dd4132' 
            : !dark && variant == ButtonVariant.success 
              ? '#0b930b' 
              : !dark && variant == ButtonVariant.secondary 
                ? '#575858' 
                : '#dd4132'} solid 2px;`
  }
}

export const SimpleButton = styled.button`
  border-radius: 16px;
  background: ${({ variant, dark }) => getColors(variant, dark, false).bgColor};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '8px 30px')};
  color: ${({ variant, dark }) => getColors(variant, dark, false).color};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: ${({ variant, dark }) => getColors(variant, dark, false).borderColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &.inverted {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, false).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, false).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, false).borderColor};
  }

  &.inverted:not(.disabled):hover {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &:not(.inverted):not(.disabled):hover {
    color: ${({ variant, dark }) => getColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${responsive.mobile.standard`
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};

  ${responsive.xs_mobile.standard`
    font-size: ${({ fontBig }) => (fontBig ? '16px' : '14px')};
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};
`;

export const SimpleButtonLink = styled.a`
  border-radius: 16px;
  background: ${({ variant, dark }) => getColors(variant, dark, false).bgColor};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '8px 30px')};
  color: ${({ variant, dark }) => getColors(variant, dark, false).color};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: ${({ variant, dark }) => getColors(variant, dark, false).borderColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &.inverted {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, false).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, false).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, false).borderColor};
  }

  &.inverted:not(.disabled):hover {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &:not(.inverted):not(.disabled):hover {
    color: ${({ variant, dark }) => getColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${responsive.mobile.standard`
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};

  ${responsive.xs_mobile.standard`
    font-size: ${({ fontBig }) => (fontBig ? '16px' : '14px')};
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};
`;

export const Button = styled(LinkS)`
  border-radius: 16px;
  background: ${({ variant, dark }) => getColors(variant, dark, false).bgColor };
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '8px 30px')};
  color: ${({ variant, dark }) => getColors(variant, dark, false).color };
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: ${({ variant, dark }) => getColors(variant, dark, false).borderColor };
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &.inverted {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, false).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, false).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, false).borderColor};
  }

  &.inverted:hover {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &:not(.inverted):hover {
    color: ${({ variant, dark }) => getColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${responsive.mobile.standard`
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};

  ${responsive.xs_mobile.standard`
    font-size: ${({ fontBig }) => (fontBig ? '16px' : '14px')};
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};
`;

export const RouteButton = styled(LinkR)`
  border-radius: 16px;
  background: ${({ variant, dark }) => getColors(variant, dark, false).bgColor};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '8px 30px')};
  color: ${({ variant, dark }) => getColors(variant, dark, false).color};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: ${({ variant, dark }) => getColors(variant, dark, false).borderColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &.inverted {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, false).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, false).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, false).borderColor};
  }

  &.inverted:hover {
    color: ${({ variant, dark }) => getInvertedColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getInvertedColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getInvertedColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &:not(.inverted):hover {
    color: ${({ variant, dark }) => getColors(variant, dark, true).color};
    background: ${({ variant, dark }) => getColors(variant, dark, true).bgColor};
    border: ${({ dark, variant }) => getColors(variant, dark, true).borderColor};
    transition: all 0.2s ease-in-out;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${responsive.mobile.standard`
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};

  ${responsive.xs_mobile.standard`
    font-size: ${({ fontBig }) => (fontBig ? '16px' : '14px')};
    padding: ${({ big }) => (big ? '14px 32px' : '8px 20px')};
  `};
`;
