import React from 'react';
import { FaRegImage } from 'react-icons/fa';
import { ImageSourceInfoLink, ImageSourceIntro } from './styles';

const ImageSourceInfo = (props) => {

  const {
    title = 'unknown image source',
    intro = 'unknown image source',
    href,
    color = 'on-dark',            // on-dark | on-light
    location = 'outline',         // inline | outline
    alignment = 'bottom-right',   // top-left | top-center | top-right | bottom-left | bottom-center | bottom-right
    verticalOffset = 0,
    showIcon = false
  } = props;

  return (
      <ImageSourceInfoLink 
        href={href} 
        title={title} 
        color={color} 
        location={location}
        alignment={alignment}
        verticalOffset={verticalOffset}
        target="_blank" 
        alt={title}
      >
        <ImageSourceIntro>{intro}</ImageSourceIntro>
        { showIcon ? <FaRegImage /> : null }
      </ImageSourceInfoLink>
  );
};

export default ImageSourceInfo;
