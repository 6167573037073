import styled from 'styled-components';
import { responsive } from '../mixins/responsive';
import { text } from '../mixins/text';

export const H1 = styled.h1`
    font-family: Montserrat, sans-serif !important;
    margin-bottom: 0;

    ${({color, weight, transform, align}) => text.text('64px', weight, '66px', align, color, transform)};

    ${({color, weight, transform, align}) => responsive.small_mobile.standard`
        ${text.text('56px', weight, '58px', align, color, transform)};
    `};
    ${({color, weight, transform, align}) => responsive.xs_mobile.standard`
        ${text.text('48px', weight, '50px', align, color, transform)};
    `};
`;

export const H2 = styled.h2`
    margin-bottom: 0;

    ${({color, weight, transform, align}) => text.text('56px', weight, '58px', align, color, transform)};

    ${({color, weight, transform, align}) => responsive.small_mobile.standard`
        ${text.text('48px', weight, '50px', align, color, transform)};
    `};
    ${({color, weight, transform, align}) => responsive.xs_mobile.standard`
        ${text.text('40px', weight, '42px', align, color, transform)};
    `};
`;

export const H3 = styled.h3`
    margin-bottom: 0;

    ${({color, weight, transform, align}) => text.text('48px', weight, '52px', align, color, transform)};

    ${({color, weight, transform, align}) => responsive.small_mobile.standard`
        ${text.text('36px', weight, '40px', align, color, transform)};
    `};
    ${({color, weight, transform, align}) => responsive.xs_mobile.standard`
        ${text.text('28px', weight, '30px', align, color, transform)};
    `};
`;

export const H4 = styled.h4`
    margin-bottom: 0;

    ${({color, weight, transform, align}) => text.text('36px', weight, '38px', align, color, transform)};

    ${({color, weight, transform, align}) => responsive.small_mobile.standard`
        ${text.text('28px', weight, '30px', align, color, transform)};
    `};
    ${({color, weight, transform, align}) => responsive.xs_mobile.standard`
        ${text.text('22px', weight, '24px', align, color, transform)};
    `};
`;

export const H5 = styled.h5`
    margin-bottom: 0;

    ${({color, weight, transform, align}) => text.text('28px', weight, '30px', align, color, transform)};

    ${({color, weight, transform, align}) => responsive.small_mobile.standard`
        ${text.text('22px', weight, '24px', align, color, transform)};
    `};
    ${({color, weight, transform, align}) => responsive.xs_mobile.standard`
        ${text.text('18px', weight, '20px', align, color, transform)};
    `};
`;

export const H6 = styled.h6`
    margin-bottom: 0;

    ${({color, weight, transform, align}) => text.text('22px', weight, '24px', align, color, transform)};

    ${({color, weight, transform, align}) => responsive.small_mobile.standard`
        ${text.text('18px', weight, '20px', align, color, transform)};
    `};
    ${({color, weight, transform, align}) => responsive.xs_mobile.standard`
        ${text.text('14px', weight, '16px', align, color, transform)};
    `};
`;

export const H7 = styled.h6`
    margin-bottom: 0;

    ${({color, weight, transform, align}) => text.text('14px', weight, '18px', align, color, transform)};

    ${({color, weight, transform, align}) => responsive.small_mobile.standard`
        ${text.text('12px', weight, '18px', align, color, transform)};
    `};
    ${({color, weight, transform, align}) => responsive.xs_mobile.standard`
        ${text.text('10px', weight, '16px', align, color, transform)};
    `};
`;
