import { css } from 'styled-components';

export const respondTo = {
    xxs: {
      standard: (...args) => css`
        @media only screen and (max-width: 374px) {
            ${css(...args)};
        }
      `,
    },
    xs: {
      standard: (...args) => css`
        @media only screen and (min-width: 375px) {
          ${css(...args)};
        }
      `,
    },
    sm: {
      standard: (...args) => css`
        @media only screen and (min-width: 600px) {
          ${css(...args)};
        }
      `,
    },
    md: {
      standard: (...args) => css`
        @media only screen and (min-width: 768px) {
          ${css(...args)};
        }
      `,
    },
    tablet: {
      standard: (...args) => css`
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          ${css(...args)};
        }
      `,
    },
    lg: {
      standard: (...args) => css`
        @media only screen and (min-width: 1024px) {
          ${css(...args)};
        }
      `,
    },
    xl: {
      standard: (...args) => css`
        @media (min-width: 1200px) {
          ${css(...args)};
        }
      `,
    },
    xxl: {
      standard: (...args) => css`
        @media (min-width: 1400px) {
          ${css(...args)};
        }
      `,
    }
  };

  export const responsive = {
    xs_mobile: {
      standard: (...args) => css`
        @media only screen and (max-width: 320px) {
            ${css(...args)};
        }
      `,
    },
    small_mobile: {
      standard: (...args) => css`
        @media only screen and (max-width: 375px) {
          ${css(...args)};
        }
      `,
    },
    mobile: {
      standard: (...args) => css`
        @media only screen and (max-width: 600px) {
          ${css(...args)};
        }
      `,
    },
    small_tablet: {
        standard: (...args) => css`
          @media only screen and (max-width: 767px) {
            ${css(...args)};
          }
        `,
      },
    tablet: {
      standard: (...args) => css`
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          ${css(...args)};
        }
      `,
    },
    desktop: {
      standard: (...args) => css`
        @media only screen and (min-width: 1024px) and (max-width: 1199px) {
          ${css(...args)};
        }
      `,
    },
    big_desktop: {
      standard: (...args) => css`
        @media (min-width: 1200px) {
          ${css(...args)};
        }
      `,
    }
  };