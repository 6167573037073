import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';

export const NavbarAnchorLink = styled(LinkS)`
  color: #2f2e2e;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  z-index: inherit;

  &.active {
    border-bottom: 3px solid #dc4132;
  }

  &:hover {
    color: #dc4132;
    transition: 0.2s ease-in-out;
  }
`;

export const NavbarRouteLink = styled(LinkR)`
  color: #2f2e2e;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  z-index: inherit;

  &.active {
    border-bottom: 3px solid #dc4132;
  }

  &:hover {
    color: #dc4132;
    transition: 0.2s ease-in-out;
  }
`;

export const NavbarLink = styled.a`
  color: ${({ isScrollNav }) => (!!isScrollNav ? '#2f2e2e' : 'transparent')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  z-index: inherit;

  &.active {
    border-bottom: 3px solid #dc4132;
  }
`;

export const SidebarAnchorLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #2f2e2e;
  weight: 500;
  cursor: pointer;
  z-index: inherit;

  &:hover {
    color: #dc4132;
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarRouteLink = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #2f2e2e;
  weight: 500;
  cursor: pointer;
  z-index: inherit;

  &:hover {
    color: #dc4132;
    transition: 0.2s ease-in-out;
  }
`;

export const FooterAnchorLink = styled(LinkS)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: .87rem;
  z-index: inherit;

  &:hover {
    color: rgb(242,154,146) !important;
    transition: 0.3s ease-out;
    cursor: pointer;
    text-decoration: none !important;
  }
`;

export const FooterRouteLink = styled(LinkR)`
  color: #fff;
  text-decoration: none !important;
  margin-bottom: 0.5rem;
  font-size: .87rem;
  z-index: inherit;

  &:hover {
    color: rgb(242,154,146);
    transition: 0.3s ease-out;
  }
`;

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: none !important;
  margin-bottom: 0.5rem;
  font-size: .87rem;
  z-index: inherit;

  &:hover {
    color: rgb(242,154,146);
    transition: 0.3s ease-out;
  }
`;

export const NormalLink = styled.a`
  color: #df423e;
  text-decoration: none !important;
  margin-bottom: 0.5rem;
  z-index: inherit;

  &:hover {
    color: #dc4132;
    text-decoration: underline !important;
    transition: 0.3s ease-out;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const NormalAnchorLink = styled(LinkS)`
  color: #df423e;
  text-decoration: none !important;
  margin-bottom: 0.5rem;
  z-index: inherit;

  &:hover {
    color: #dc4132;
    text-decoration: underline !important;
    transition: 0.3s ease-out;
  }
`;

export const NormalRouteLink = styled(LinkR)`
  color: #df423e;
  text-decoration: none !important;
  margin-bottom: 0.5rem;
  z-index: inherit;

  &:hover {
    color: #dc4132;
    text-decoration: underline !important;
    transition: 0.3s ease-out;
  }
`;