import styled from 'styled-components';
import { responsive, respondTo } from '../components/mixins/responsive';
import { ContentFloat, ContentVerticalFloat } from '../types';
import CardBlock from '../components/CardBlock';

export const SectionWrapper = styled.div`
    margin: 40px 20px 10px 20px;
    position: relative;

    ${responsive.tablet.standard`
        margin: -20px 0 0 0;
    `};

    ${responsive.mobile.standard`
        margin: -20px 0 0 0;
    `};
    ${responsive.xs_mobile.standard`
        margin: -25px 0;
    `};
`;

export const PageContentSectionBlock = styled.div`
    position: relative;
    width: 100%;

    ${respondTo.xxs.standard`
        padding: 0 32px;
    `};

    ${respondTo.xs.standard`
        padding: 0 40px;
    `};

    ${respondTo.sm.standard`
        padding: 0 40px;
    `};

    ${respondTo.md.standard`
        padding: 0 32px;
    `};

    ${respondTo.lg.standard`
        padding: 0 32px;
    `};
`;

export const PageContentBlock = styled.div`
    position: relative;
    width: 100%;

    ${respondTo.xxs.standard`
        padding: 0 8px;
    `};

    ${respondTo.xs.standard`
        padding: 0 16px;
    `};

    ${respondTo.sm.standard`
        padding: 0 16px;
    `};

    ${respondTo.md.standard`
        padding: 0;
    `};

    ${respondTo.lg.standard`
        padding: 0;
    `};
`;

export const PageContentAlignment = styled.div`
    display: flex;
    flex-wrap: ${({ wrap = 'nowrap' }) => wrap };
    justify-content: ${({ align = ContentFloat.left }) => 
        align === ContentFloat.center ? 'center' : align === ContentFloat.right ? 'flex-end' : 'flex-start' 
    };
    align-items: ${({ vAlign = ContentFloat.left }) => 
        vAlign === ContentVerticalFloat.top ? 'flex-start' : vAlign === ContentFloat.bottom ? 'flex-end' : 'center'
    };
    position: relative;
    width: 100%;
    height: ${({ fullHeight = false }) => !!fullHeight ? '100%' : 'auto' };

    ${({ noGap = false }) => respondTo.xxs.standard`
        flex-direction: column;
        column-gap: ${ !!noGap ? '0' : '32px' };
        row-gap:  ${ !!noGap ? '0' : '32px' };
    `};

    ${({ noGap = false }) => respondTo.xs.standard`
        flex-direction: column;
        column-gap: ${ !!noGap ? '0' : '32px' };
        row-gap:  ${ !!noGap ? '0' : '32px' };
    `};

    ${({ noGap = false }) => respondTo.sm.standard`
        flex-direction: column;
        column-gap: ${ !!noGap ? '0' : '48px' };
        row-gap:  ${ !!noGap ? '0' : '48px' };
    `};

    ${({ noGap = false }) => respondTo.md.standard`
        flex-direction: row;
        column-gap: ${ !!noGap ? '0' : '56px' };
        row-gap:  ${ !!noGap ? '0' : '56px' };
    `};
`;

export const PageContentAlignmentCol = styled.div`
    flex-grow: ${({ grow = 1 }) => grow };
`;

export const CardBlockNew = styled(CardBlock)`
    height: 250px;
`;