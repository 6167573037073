import styled from 'styled-components';

export const BlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 75px;
  position: relative;
  height: 100%;

  @media screen and (max-width: 1100px) {
    padding: 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;