import React, { useState, useEffect, useLayoutEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import { TextSize, NavLinkStyleTypes } from '../../types';
import {
  HeroContainer,
  SlideBg,
  CarouselWrapper,
  SlideContentWrapper,
  SlideContent,
  BtnWrap
} from './styles';
import ImageSourceInfo from '../ImageSourceInfo';

import SectionTitle from '../SectionTitle';
import BodyText from '../BodyText';
import Spacer from '../Spacer';
import ContainerGrid from '../ContainerGrid';
import NavigationLink from '../NavigationLink';
import { RouteButton, Button } from '../ButtonElements';

const HeroSection = (props) => {

  const { slides = [] } = props;

  const updateSlides = (slidesData = []) => {
    return slidesData.map(slide => {
      return {
        ...slide,
        sectionHeight: '100%',
        className: 'd-flex h-100'
      }
    });
  };

  let updatedSlides = updateSlides(slides);

  const [isMobile, setIsMobile] = useState(false);
  const [doAnimation, setDoAnimation] = useState(true);
  const [stateHeroUpdatedSlides, setStateHeroUpdatedSlides] = useState(updatedSlides);
  const [stateHeroSlideCurrentIndex, setStateHeroSlideCurrentIndex] = useState(0);

  const isDev = process.env.NODE_ENV === 'development';
  const defaultBgImage = 'DM168-ray-reits-image-adobe-stock.jpeg';  

  const endFadeInAnimation = () => {
    setDoAnimation(false);
  }

  const onCarouselItemChanged = (itemIdx) => {
    setDoAnimation(true);
    setStateHeroSlideCurrentIndex(itemIdx);
  };

  useEffect(() => {
    updatedSlides = updateSlides(slides);
    setStateHeroUpdatedSlides(updatedSlides);
    onCarouselItemChanged(0);
  }, [props]);

  useLayoutEffect(() => {
    function updateSize() {
      const isMobileSize = window.innerWidth < 768 || window.innerHeight < 768;
      setIsMobile(isMobileSize);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <HeroContainer id='home' className='hero'>
      <SlideBg 
          backgroundImage={stateHeroUpdatedSlides[stateHeroSlideCurrentIndex]?.img || defaultBgImage} 
          backgroundOverlay={ stateHeroUpdatedSlides[stateHeroSlideCurrentIndex]?.imgOverlay } 
          backgroundOverlayTransparency={ stateHeroUpdatedSlides[stateHeroSlideCurrentIndex]?.imgOverlayTransparency }
          className= { doAnimation && 'bg-fade-in' }
          onAnimationEnd={ endFadeInAnimation }
        >
          <ContainerGrid className='h-100'>
            <SlideContentWrapper>
              <CarouselWrapper displayBackground={ true } variant={ 'compact' }>
                <Carousel 
                  autoFocus={ true } 
                  showThumbs={ false } 
                  showStatus={ false } 
                  useKeyboardArrows={ !isMobile }
                  showArrows={ true }
                  swipeable={ true }
                  emulateTouch={ true }
                  autoPlay={ true } 
                  interval={ 7000 } 
                  transitionTime={ 700 }
                  infiniteLoop 
                  stopOnHover={ !isMobile }
                  dynamicHeight={ true }
                  className="presentation-mode-"
                  onChange={ onCarouselItemChanged }
                >
                  {
                    stateHeroUpdatedSlides.map((slide, index) => {
                      return (
                        <SlideContent key={ index } className={ `my-slide ${ index == 0 ? 'primary' : 'secondary'} complex` } isMobile={ isMobile }>
                          <SectionTitle topText={ slide?.topLine } headerText={ slide?.headline } textSize={ TextSize.large } darkText={ slide?.darkText } headerFloat={ slide?.headerFloat }>
                            <BodyText text={ slide?.description } alignment={ 'justify' } />
                          </SectionTitle>
                          <BtnWrap>
                          {
                            slide?.to
                              ? <Button
                                  to={ slide?.to || 'home' }
                                  smooth={ true }
                                  duration={ 500 }
                                  spy={ true }
                                  exact='true'
                                  offset={ -80 }
                                  primary={ slide?.primary ? 1 : 0 }
                                  dark={ slide?.dark ? 1 : 0 }
                                  dark2={ slide?.dark2 ? 1 : 0 }
                                  inverted={ slide?.inverted ? 1 : 0 }
                                >
                                  { slide?.buttonLabel }
                                </Button>
                              : !slide?.isExternalLink 
                                ? <RouteButton
                                    to={ slide?.routeTo || '/' }
                                    smooth={ true }
                                    duration={ 500 }
                                    spy={ true }
                                    exact='true'
                                    offset={ -80 }
                                    primary={ slide?.primary ? 1 : 0 }
                                    dark={ slide?.dark ? 1 : 0 }
                                    dark2={ slide?.dark2 ? 1 : 0 }
                                    inverted={ slide?.inverted ? 1 : 0 }
                                  >
                                    { slide?.buttonLabel }
                                  </RouteButton>
                                : <NavigationLink
                                    title={ slide?.buttonLabel }
                                    to={ slide?.routeTo } 
                                    style={ NavLinkStyleTypes.button } 
                                    isExternalLink={ true } 
                                    isScrollNav={ false } 
                                    className= { slide?.inverted ? 'inverted' : '' } 
                                    primary={ slide?.primary ? 1 : 0 }
                                    dark={ slide?.dark } />
                          }
                          </BtnWrap>
                          <Spacer size='md' />
                        </SlideContent>
                      )
                    })
                  }
                </Carousel>
              </CarouselWrapper>
            </SlideContentWrapper>
          </ContainerGrid>

          <ImageSourceInfo 
              href="https://www.istockphoto.com" 
              title="" 
              intro="sophinex investment licence" 
              color="on-dark" 
              location="inline" />
      </SlideBg>
    </HeroContainer>
  )
}

export default HeroSection;
