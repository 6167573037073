import React from 'react';

import { SectionWrapper } from './styles';
import { TextSize } from '../types';
import GeneralLayout from '../layouts/GeneralLayout';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import { NavLinkStyleTypes } from '../types';
import { LayoutTypes } from '../types';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';
import { COMPANY_FULL_NAME } from '../shared/globals';
import { getMainMenuItems } from '../utils/nav-menu';
import BodyText from '../components/BodyText';

const CaNoticeAtCollectionPage = () => {

  const navOptions = {
    ...getMainMenuItems(LayoutTypes.main),
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout {...navOptions}>
      <SectionWrapper>
        <Section id={'ca-notice-at-collection'} lightBg={true}>
          <SectionTitle
            topText={ 'January 2023' }
            headerText={ 'California Consumer Privacy Act (CCPA) Notice at Collection' }
            textSize={ TextSize.middle }
          >
            <BodyText type='div'>
              <p><b>{ COMPANY_FULL_NAME}</b> (“Company”, “We,” “Us,”) collects Personal Information from you in the context of our business relationship. We use the information we collect to:</p>
              <ul>
                <li>manage and fulfill requests, deliver services, process payments;</li>
                <li>respond to requests for information and services; and</li>
                <li>improve our services.</li>
              </ul>
              <p>We also use the information we collect in anonymized, aggregated form for customer preference analyses, and to:</p>
              <ul>
                <li>develop new services;</li>
                <li>develop partner relationships; and</li>
                <li>determine how to best protect the data of our company and our customers.</li>
              </ul>
              <p><b>WE DO NOT SELL YOUR PERSONAL INFORMATION.</b></p>
              <p>For detailed information about what data we collect, how it's used, who we share it with, and your rights and choices about your Personal Information, refer to our <NavigationLinkWithContext title={ 'Privacy Policy' } path={ '/privacy-policy' } style={ NavLinkStyleTypes.normal } alt={ 'Privacy Policy' } />.</p>
            </BodyText>
          </SectionTitle>
        </Section>
      </SectionWrapper>
    </GeneralLayout>
  );
}

export default CaNoticeAtCollectionPage;
