import React from 'react';

import { isWidthOrHeightValid } from '../../utils';
import {
  Container,
  Wrapper
} from './styles';

const Section = (props) => {

  const { id, lightBg, transparent, height, className, style } = props;

  let sectionRef = React.createRef();

  return (
    <Container
      id={ id }
      ref={ sectionRef }
      lightBg={ lightBg }
      transparent={ transparent }
      className={ `${ className || '' } section-container` }
      style={ style }
     >
      <Wrapper
        height={isWidthOrHeightValid(height) ? height : null}
        className='section-wrapper'
      >
        { props.children }
      </Wrapper>
    </Container>
  );
};

export default Section;
