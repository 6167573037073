import React, { useContext } from 'react';
import { NavigationContext } from '../../context/NavigationContext';

const WithNavigationContext = (props) => {

  const navigationContext = useContext(NavigationContext);

  const propsWithContex = { ...props, ...navigationContext };

  return (
    <>
      { props.render(propsWithContex) }
    </>
  );
};
  
export default WithNavigationContext;