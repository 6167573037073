import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';

export const HeroContainer = styled.div`
  background: #16182a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100vh;
  width: 100%;
  position: relative;

  & button.control-arrow {
    position: absolute;
    top: unset !important;
    bottom: 0;
  }
`;

export const HeroBg = styled.div`
  background-image: ${(props) => `url(${require(`../../images/${props.backgroundImage}`)})`};
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1360px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const SlideBg = styled.div`
  background-image: ${(props) => `url(${require(`../../images/${props.backgroundImage}`)})`};
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  :before {
    content: '';
    background: ${(props) => !!props?.backgroundOverlay ? `rgba(0, 0, 0, ${props?.backgroundOverlayTransparency || '0.3'})` : "none"};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
`;

export const SlideContentWrapper = styled.div`
  grid-column: 1/13;
  display: flex;
  align-items: center;
  z-index: 3;
  position: relative;
  padding-top: 100px;
  width: 100%;
  height: 100%;

  ${respondTo.xxs.standard`
      padding: 0 8px;
  `};

  ${respondTo.xs.standard`
      padding: 0;
  `};

  ${respondTo.sm.standard`
      padding: 0 16px;
  `};

  ${respondTo.md.standard`
      padding: 0;
  `};

  ${respondTo.lg.standard`
      padding: 0;
  `};
`;

export const CarouselWrapper = styled.div`
  padding: ${({ displayBackground, variant }) => !displayBackground ? "0 15px" : variant === 'compact' ? "20px 25px" : "50px 55px" };
  border-radius: ${({ displayBackground }) => displayBackground ? "20px" : "0" };
  background-color: ${({ displayBackground }) => displayBackground ? "rgba(0,0,0,0.4)" : "none" };
  width: 100%;
  max-width: 650px;
  position: relative;
  z-index: inherit;

  ${respondTo.xxs.standard`
    padding: ${({ displayBackground, variant }) => !displayBackground ? "0 15px" : variant === 'compact' ? "10px" : "20px" };
    max-width: 100%;
  `};
  ${respondTo.xs.standard`
    padding: ${({ displayBackground, variant }) => !displayBackground ? "0 15px" : variant === 'compact' ? "20px 10px" : "50px 55px" };
    max-width: 100%;
  `};
  ${respondTo.sm.standard`
    padding: ${({ displayBackground, variant }) => !displayBackground ? "0 15px" : variant === 'compact' ? "20px 25px" : "50px 55px" };
    max-width: 100%;
  `};
  ${respondTo.lg.standard`
    padding: ${({ displayBackground, variant }) => !displayBackground ? "0 15px" : variant === 'compact' ? "20px 25px" : "50px 55px" };
    max-width: 650px;
  `};
`;

export const SlideContent = styled.div`
  padding: 0 10px;
  width: 100%;
  position: relative;
  z-index: inherit;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
