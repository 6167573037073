import React from 'react';
import { ContainerGridWrapper } from './styles';

const ContainerGrid = (props) => {

  const {
    id,
    children,
    takeFullViewport = false,
    isFullWidth = false
  } = props;

  return (
    <ContainerGridWrapper { ...props } takeFullViewport={ takeFullViewport} isFullWidth={ isFullWidth }>
      { children }
    </ContainerGridWrapper>
  );
};

export default ContainerGrid;
