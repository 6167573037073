import { isEmpty } from 'lodash';

export const isWidthOrHeightValid = (value) => {
  return /\d(p|x|%|v|h)/.test(value);
};

export const getNavLink = (isScrollNav, path, isExternalLink, hash = '') => {
  return isScrollNav && !isEmpty(hash) && !isExternalLink
    ? hash.toString()
    : !isEmpty(hash)
      ? `${path}#${hash.toString()}`
      : path;
}