import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';
import { hexToRgb } from '../../utils/colors';

const getColorString = (hexColor = 'transparent', transparency = 0.3) => {
    if (!hexColor || /^\s*$/.test(hexColor)) {
      return 'none';
    }
    if (hexColor === 'transparent') {
      return hexColor;
    }
    const arrRgb = hexToRgb(hexColor);
    return `rgba(${arrRgb[0]}, ${arrRgb[1]}, ${arrRgb[2]}, ${transparency})`;
  }

export const CardWrapper = styled.div`
    padding: ${({ hasImage }) => hasImage ? '0 16px 16px 16px' : '16px'};
    margin: 0 16px 32px 16px;
    position: relative;
    min-height: ${({ minHeight }) => minHeight ? minHeight : '100px'};
    width: ${({ maxWidth = 'unset' }) => maxWidth };
    color: ${({ color }) => color && color.length > 0 ? color : 'inherit'};
    background-color: ${({ bgColor }) => bgColor && bgColor.length > 0 ? bgColor : 'transparent'};
    border: ${({ borderColor }) => getColorString(borderColor, 0.15)} solid 1px;
    border-radius: 8px;

    ${respondTo.xxs.standard`
        margin-right: 12px;
    `};

    ${respondTo.xs.standard`
        margin-right: 12px;
    `};

    ${respondTo.md.standard`
        margin-right: 16px;
    `};
`;

export const CardImage = styled.img`
    display: ${({ image }) => image ? 'block' : 'none'};
`;

export const CardContent = styled.div`
  & h6 {
    color: #747474;
    letter-spacing: 2px;
    text-transform: uppercase;;
  }
`;