import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';
import { SpacerSize } from '../../types';

const sizes = {
  xs: {
    xxs: '10px',
    xs: '15px',
    sm: '24px',
    md: '24px',
    lg: '24px',
    xl: '24px',
    xxl: '24px'
  },
  sm: {
      xxs: '15px',
      xs: '20px',
      sm: '32px',
      md: '32px',
      lg: '32px',
      xl: '32px',
      xxl: '32px'
  },
  md: {
      xxs: '35px',
      xs: '40px',
      sm: '64px',
      md: '64px',
      lg: '64px',
      xl: '64px',
      xxl: '64px'
  },
  lg: {
      xxs: '70px',
      xs: '80px',
      sm: '128px',
      md: '128px',
      lg: '128px',
      xl: '128px',
      xxl: '128px'
  }
}

const sizes_ = {
    sm: {
        xxs: '16px',
        xs: '16px',
        sm: '32px',
        md: '32px',
        lg: '48px',
        xl: '48px',
        xxl: '48px'
    },
    md: {
        xxs: '24px',
        xs: '24px',
        sm: '48px',
        md: '48px',
        lg: '56px',
        xl: '56px',
        xxl: '56px'
    },
    lg: {
        xxs: '32px',
        xs: '32px',
        sm: '48px',
        md: '48px',
        lg: '64px',
        xl: '64px',
        xxl: '64px'
    }
}

const getSpacerHeightBySize = (size = SpacerSize.md, breakpoint = 'lg') => {
    return sizes[size][breakpoint] || '32px';
}

export const SpacerConteiner = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  ${respondTo.xxs.standard`
    height: ${({ size }) => getSpacerHeightBySize(size, 'xxs')};
  `};

  ${respondTo.xs.standard`
    height: ${({ size }) => getSpacerHeightBySize(size, 'xs')};
  `};

  ${respondTo.sm.standard`
    height: ${({ size }) => getSpacerHeightBySize(size, 'sm')};
  `};

  ${respondTo.md.standard`
    height: ${({ size }) => getSpacerHeightBySize(size, 'md')};
  `};

  ${respondTo.lg.standard`
    height: ${({ size }) => getSpacerHeightBySize(size, 'lg')};
  `};

  ${respondTo.xl.standard`
    height: ${({ size }) => getSpacerHeightBySize(size, 'xl')};
  `};

  ${respondTo.xxl.standard`
    height: ${({ size }) => getSpacerHeightBySize(size, 'xxl')};
  `};
`;