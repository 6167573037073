export const COMPANY_NAME = 'Sophinex Group';
export const COMPANY_FULL_NAME = `${COMPANY_NAME}, LLC`;
export const COMPANY_BUSINESS_HOURS_1 = 'Mon–Fri 10:00 am – 6:00 pm';
export const COMPANY_BUSINESS_HOURS_2 = 'Sat 10:00 am – 5:00 pm';
export const COMPANY_BUSINESS_HOURS_TZ = 'Central Time';
export const COMPANY_PHONE = '(877) 710-6030';
export const COMPANY_PHONE_ALT = '(877) 710-6030';
export const COMPANY_FAX = '(877) 710-6030';
export const COMPANY_EMAIL = 'info@sophinex.com';
export const COMPANY_ADDRESS = '4171 Lomac St';
export const COMPANY_ADDRESS_2 = 'Ste F #1110';
export const COMPANY_CITY = 'Montgomery';
export const COMPANY_STATE = 'AL';
export const COMPANY_POSTAL_CODE = '36106';
export const COMPANY_DESCRIPTION = `${ COMPANY_NAME } is a privately held and operated company with a great vision, expertise and experience of doing smart investments in real estate.`;
export const COMPANY_SLOGAN = 'As a privately held and operated business, we work hard to build trust and strong relationships with all of our clients and partners. That is why we remain at the top of our game.';

export const SERVICE_STATES = 'Alabama, Arizona, Arkansas, Florida, Georgia, Kansas, Kentucky, North Carolina, Oklahoma, South Carolina, Tennessee and Virginia';
export const SERVICE_STATES_SHORT = 'AL, AZ, AR, FL, GA, KS, KY, NC, OK, SC, TN and VA';

export const COPYRIGHT = `© 2024 ${COMPANY_FULL_NAME}. All rights reserved.`;