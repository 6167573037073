import React from 'react';
import { H1, H2, H3, H4, H5, H6, H7} from './styles';
import { HeaderTextTypes, ContentFloat } from '../../types';

const HeaderText = (props) => {

  const { 
    text, 
    type = HeaderTextTypes.h1, 
    color = 'inherit', 
    weight = 600,
    align = ContentFloat.left, 
    transform = 'none',
    children
  } = props;

  const updatedProps = {
    ...props,
    text, 
    type, 
    color,
    weight, 
    align, 
    transform
  }

  return (
    <>
      {
          type === 'h2' 
              ? <H2 { ...updatedProps }>{ text || children }</H2> 
              : type === 'h3' 
                  ? <H3 { ...updatedProps }>{ text || children }</H3> 
                  : type === 'h4' 
                      ? <H4 { ...updatedProps }>{ text || children }</H4> 
                      : type === 'h5' 
                          ? <H5 { ...updatedProps }>{ text || children }</H5> 
                          : type === 'h6' 
                              ? <H6 { ...updatedProps }>{ text || children }</H6>
                              : type === 'h7' 
                                  ? <H7 { ...updatedProps }>{ text || children }</H7>
                                  : <H1 { ...updatedProps }>{ text || children }</H1>
      }
    </>
  );
}

export default HeaderText;
