import React, { useState, useEffect } from 'react';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';

import { LayoutTypes, NavLinkStyleTypes } from '../../types'
import NavigationLinkWithContext from '../NavigationLinkWithContext';
import { COMPANY_PHONE_ALT } from '../../shared/globals';

import Logo from '../Logo';
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLogo,
  NavMenu,
  NavBtn,
  NavStaticItem,
  Phone,
  PhoneIcon,
  PhoneNumber
} from './styles';
import { RouteButton } from '../ButtonElements';
import { PageContentAlignment } from '../../pages/styles';

const DynamicNavbar = ({ baseUrl, layoutType, menuItems, menuButton, isOpen, setIsOpen, onClick, isBgDark = true }) => {

  const [scrollNav, setScrollNav] = useState(layoutType === LayoutTypes.main);
  const [islNavLinksVisible, setIslNavLinksVisible] = useState(false);

  const changeNav = () => {
    if (layoutType === LayoutTypes.main || window.scrollY > 100) {
      setIslNavLinksVisible(true);
    } else {
      setIslNavLinksVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    changeNav();
  }, [layoutType]);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const mobileIconClickHandler = (e) => {
    setIsOpen(!isOpen);
    if (isFunction(onClick)) {
      onClick(e);
    }
  }

  return (
    <>
      <IconContext.Provider value={{ color: !isBgDark || islNavLinksVisible ? '#6a6a6a' : '#ffffff' }}>
        <Nav showNav={ islNavLinksVisible } className='nav-wrapper'>
          <NavbarContainer showNav={ islNavLinksVisible }>
            <NavLogo onClick={toggleHome} to='/'>
              <Logo showNav={islNavLinksVisible} isBgDark={isBgDark} />
            </NavLogo>
            {
              layoutType === LayoutTypes.subdom 
                ? null 
                : <MobileIcon showNav={ islNavLinksVisible } onClick={mobileIconClickHandler}>
                    <FaBars />
                  </MobileIcon>
            }
            <PageContentAlignment align='right' vAlign='center' fullHeight={ true } noGap={ true } className='d-flex align-items-baseline'>
              <NavMenu hidden={!islNavLinksVisible} className='nav-menu'>
                { menuItems.map((item, key) => (
                  <NavItem key={key}>
                    <NavigationLinkWithContext 
                      { ...item} 
                      path={ item.path } 
                      style={ NavLinkStyleTypes.navbar } 
                      alt={ item.title } 
                      key={key}
                    />
                  </NavItem>
                ))}
              </NavMenu>

              <NavStaticItem className='d-flex phone-menu-item'>
                <Phone>
                  <PhoneIcon isBgDark={ !islNavLinksVisible && isBgDark }>
                    <svg xmlNs="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 384 384" width="16" height="16" xmlSpace="preserve" className="contact-type-svg-content">
                      <g>
                        <g>
                          <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594    c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448    c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813    C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"></path>
                        </g>
                      </g>
                      <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                    </svg>
                  </PhoneIcon>    
                  <PhoneNumber isBgDark={ !islNavLinksVisible && isBgDark }>{ COMPANY_PHONE_ALT }</PhoneNumber>
                </Phone>
                {
                  !!menuButton ? <NavBtn className='ps-4'>
                    <NavigationLinkWithContext { ...menuButton } />
                    <RouteButton className='d-none' primary dark={ !isBgDark } to={ menuButton.path} target={ menuButton.isExternalLink ? "_blank" : "_self"}>{ menuButton.title }</RouteButton>
                  </NavBtn> : null
                }
              </NavStaticItem>
              
            </PageContentAlignment>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

DynamicNavbar.propTypes = {
  baseUrl: PropTypes.string,
  layoutType: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  menuButton: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClick: PropTypes.func
};

DynamicNavbar.defaultProps = {
  baseUrl: '/', 
  layoutType: LayoutTypes.main, 
  menuItems: [],
  menuButton: null,
  isOpen: false, 
  setIsOpen: (isOpen) => {}, 
  onClick: (event) => {}
};

export default DynamicNavbar;
