import styled from 'styled-components';

export const AvatarContainer = styled.div`
    position: relative;
    /*border-radius: 50%;*/
    width: 50px;
    height: 50px;
    /*background-color: #cfcfcf;
    z-index: 0;*/
`;

export const AvatarImage = styled.div`
    width: 60px;
    /*border-radius: 50%;
    background-color: #ffffff;
    z-index: 1;*/
`;
