import styled from 'styled-components';
import { responsive } from '../mixins/responsive';

export const PageContentContainer = styled.div`
  margin: auto;
  padding: 40px 20px 40px 40px;  
  position: relative;
  width: 100%;
  max-width: 1360px;
  color: #111;

  ${responsive.small_mobile.standard`
    padding: 40px 20px 20px 20px; 
  `};
`;

export const ContentContainer = styled.div`
  padding-bottom: 40px;
  width: 100%;
  position: relative;

  ${responsive.small_mobile.standard`
    padding-bottom: 0; 
  `};
`;

export const ColoredShape = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: -40px;
  width: 30%;
  height: 100%;
  z-index: -1;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: rgba(0, 0, 0, .2);

  ${responsive.tablet.standard`
    width: 20%;
  `};
  ${responsive.small_tablet.standard`
    width: 20%;
    border-top-left-radius: 0;
  `};
  ${responsive.small_mobile.standard`
    display: none;
  `};
  ${responsive.xs_mobile.standard`
    width: 15%;
  `};
`;

export const PageHeaderContainer = styled.div`
  margin-bottom: 0;
  width: 70%;
  position: relative;

  ${responsive.small_mobile.standard`
    width: 100%;
  `};
`;

export const ContactTypesContainer = styled.div`
  margin-bottom: 40px;
  padding: 0 40px 40px 40px;
  border-radius: 30px;
  width: 96%;
  background-color: #f6f6f6;
  position: relative;

  ${responsive.small_mobile.standard`
    padding: 0px 20px 0px 20px;
    width: 100%;
  `};
`;

export const ContactFormContainer = styled.div`
  padding: 40px 40px 20px 0;
  border-radius: 30px;
  width: 96%;
  background-color: #fcfcfc;
  position: relative;

  ${responsive.small_mobile.standard`
    padding: 10px 0px 0px 0px;
    width: 100%;
  `};
`;