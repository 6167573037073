import React from 'react';
import PropTypes from 'prop-types';

import { history } from '../helpers/history';
import { ToastService, ToastType, defaultId } from '../services/toast.service';
import { ToastContainer, Toast } from 'react-bootstrap';

const propTypes = {
  id: PropTypes.string,
  autohide: PropTypes.bool,
  delay: PropTypes.number,
  fade: PropTypes.bool,
  keepAfterRouteChange: PropTypes.bool
};

const defaultProps = {
  id: defaultId,
  autohide: true,
  delay: 3000,
  fade: true,
  keepAfterRouteChange: false
};

class ToastMessage extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        toasts: []
      };
    }

    componentDidMount() {
      // Subscribe to new alert notifications
      this.subscription = ToastService.onToast(this.props.id)
        .subscribe(toast => {
          // Clear alerts when an empty alert is received
          if (!toast.message) {
            // Filter out alerts without 'keepAfterRouteChange' flag
            const toasts = this.state.toasts.filter(x => x.keepAfterRouteChange);
            // Remove 'keepAfterRouteChange' flag on the rest
            toasts.forEach(x => delete x.keepAfterRouteChange);
            this.setState({ toasts });
            return;
          }
          // Add alert to array
          this.setState({
            toasts: [...this.state.toasts, toast]
          });
          // Auto close alert if required
          if (toast.autoClose) {
            setTimeout(() => this.removeToast(toast), 3000);
          }
        });

      // Clear alerts on location change
      this.historyUnlisten = history.listen(() => {
        ToastService.clear(this.props.id);
      });
    }

    componentWillUnmount() {
      this.subscription.unsubscribe();
      this.historyUnlisten();
    }

    removeToast(toast) {
      if (this.props.fade) {
        // Fade out alert
        const alertWithFade = { ...toast, fade: true };
        this.setState({
          toasts: this.state.toasts.map(x => x === toast ? alertWithFade : x)
        });
        // Remove alert after faded out
        setTimeout(() => {
          this.setState({ toasts: this.state.toasts.filter(x => x !== alertWithFade) })
        }, 250);
      } else {
        // Remove alert
        this.setState({
          toasts: this.state.toasts.filter(x => x !== alert)
        })
      }
    }

    cssClasses(toast) {
      if (!toast) {
        return;
      }
      const classes = [];    

      const toastTypeClass = {
        [ToastType.success]: 'toast toast-success',
        [ToastType.error]: 'toast toast-danger',
        [ToastType.info]: 'toast toast-info',
        [ToastType.warning]: 'toast toast-warning'
      }
      classes.push(toastTypeClass[toast.type]);

      if (toast.fade) {
        classes.push('fade');
      }
      return classes.join(' ');
    }

    render() {
      const { toasts } = this.state;

      if (!toasts.length) {
        return null;
      }
      return (
        <ToastContainer className="toast-container" position={ 'top-end' }>
          {toasts.map((toast, index) =>
            <Toast onClose={() => this.removeToast(toast)} className={this.cssClasses(toast)} autohide={ toast.autohide } delay={ toast.delay } key={ index }>
              <Toast.Header>
                <strong className="me-auto">{ toast.title }</strong>
                <small>{ toast.subTitle }</small>
              </Toast.Header>
              <Toast.Body>{ toast.message }</Toast.Body>
            </Toast>
          )}
        </ToastContainer>
      );
    }
}

ToastMessage.propTypes = propTypes;
ToastMessage.defaultProps = defaultProps;

export default ToastMessage;