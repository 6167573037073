import React, { useContext, useEffect, useState } from 'react';
import { NavigationContext } from '../../context/NavigationContext';
import { LayoutTypes } from '../../types';
import { getNavLink } from '../../utils';
import NavigationLink from '../NavigationLink';

const NavigationLinkWithContext = (props) => {

    const { layoutType } = useContext(NavigationContext);
    const [stateLayoutType, setStateLayoutType] = useState(layoutType);

    let isScrollNav = layoutType === LayoutTypes.home;
    let isExternalLink = layoutType === LayoutTypes.subdom;
    let linkTo = getNavLink(isScrollNav, props.path, isExternalLink, props.hash);

    useEffect(() => {
      if (stateLayoutType !== layoutType) {
        isScrollNav = layoutType === LayoutTypes.home;
        isExternalLink = layoutType === LayoutTypes.subdom;
        linkTo = getNavLink(isScrollNav, props.path, isExternalLink, props.hash);

        setStateLayoutType(layoutType);
      }
    }, [layoutType]);

    return (
      <NavigationLink { ...props } to={ linkTo } isScrollNav={ isScrollNav } />
    );
  };
  
  export default NavigationLinkWithContext;