import React from 'react';
import PropTypes from 'prop-types';
import { ContentFloat, HeaderTextTypes, TextSize } from '../../types';

import {
  Container,
  HeaderWrapper,
  TopLine,
  Header,
  HeaderHorizLineWrapper,
  HeaderHorizLine,
  ContentWrapper
} from './styles';

import HeaderText from '../HeaderText';

class SectionTitle extends React.Component {

  constructor(props) {
    super(props);
  }

  getHeaderTopTextTypeBySize(textSize = TextSize.middle) {
    switch (textSize) {
      case TextSize.large:
        return HeaderTextTypes.h7;
      case TextSize.small:
        return HeaderTextTypes.h7;
      default:
        return HeaderTextTypes.h7;
    }
  }

  getHeaderTextTypeBySize(textSize = TextSize.middle) {
    switch (textSize) {
      case TextSize.large:
        return HeaderTextTypes.h3;
      case TextSize.small:
        return HeaderTextTypes.h5;
      default:
        return HeaderTextTypes.h4;
    }
  }

  render() {
    const { 
      topText, 
      headerText, 
      textSize, 
      leftPadding = 0, 
      rightPadding = 0, 
      topPadding = 0, 
      bottomPadding = 0, 
      darkText = false, 
      children, 
      isVertical = false, 
      headerFloat = ContentFloat.left,
      weight = 700,
      className
    } = this.props;

    return (
      <Container 
        topPadding={ topPadding } 
        rightPadding={ rightPadding } 
        bottomPadding={ bottomPadding } 
        leftPadding={ leftPadding } 
        darkText={ darkText }
        className={ className }
      >
        <HeaderWrapper displayLine={ !isVertical } float={ headerFloat }>
          <TopLine 
            text={ topText } 
            textSize={ textSize } 
            alignment={isVertical ? 'center' : headerFloat === ContentFloat.right ? headerFloat : 'left'}
          >
            <HeaderText
              text={ topText } 
              type={ this.getHeaderTopTextTypeBySize(textSize) }
              weight={ 700 }
              color={ '#dd4132' }
              align={ headerFloat }
              transform = { 'uppercase' }
            />
          </TopLine>
          <Header 
            text={ headerText } 
            type={ this.getHeaderTextTypeBySize(textSize) } 
            textSize={ textSize } 
            weight={ weight }
            displayLine={ !isVertical }
            float={ headerFloat }
          >
            <HeaderText
              text={ headerText } 
              weight={ weight }
              type={ this.getHeaderTextTypeBySize(textSize) } 
              align={ headerFloat }
            />
          </Header>
          <HeaderHorizLineWrapper hidden={!isVertical}>
            <HeaderHorizLine/>
          </HeaderHorizLineWrapper>
        </HeaderWrapper>
        <ContentWrapper textSize={ textSize }>
          { children }
        </ContentWrapper>
      </Container>
    );
  }
}

SectionTitle.propTypes = {
  topText: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  headerFloat: PropTypes.string,
  textSize: PropTypes.string,
  topPadding: PropTypes.string,
  rightPadding: PropTypes.string,
  bottomPadding: PropTypes.string,
  leftPadding: PropTypes.string,
  darkText: PropTypes.bool,
  isVertical: PropTypes.bool
};

SectionTitle.defaultProps = {
  topText: '',
  headerText: '',
  headerFloat: ContentFloat.left,
  textSize: TextSize.large,
  topPadding: 0,
  rightPadding: 0,
  bottomPadding: 0,
  leftPadding: 0,
  darkText: true,
  isVertical: false
};

export default SectionTitle;