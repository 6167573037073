import React from 'react';
import { SPAN, P, DIV, LABEL } from './styles';
import { TextSizes, TextTypes } from '../../types';

const BodyText = (props) => {

  const { 
    text = '', 
    size = TextSizes.md, 
    weight = 400, 
    color = 'inherit', 
    transform = 'none', 
    type = TextTypes.p, 
    alignment = 'left', 
    children
  } = props;

  const updatedProps = {
    ...props,
    text,
    size, 
    weight, 
    color, 
    transform, 
    type, 
    alignment
  };

  return (
    <>
      {
          type === TextTypes.span
              ? <SPAN { ...updatedProps }>{ text }</SPAN> 
              : type === TextTypes.p
                  ? <P  { ...updatedProps }>{ text }</P> 
                  : type === TextTypes.label
                    ? <LABEL { ...updatedProps }>{ text }</LABEL> 
                    : <DIV { ...updatedProps }>{ children }</DIV>
      }
    </>
  );
}

export default BodyText;
