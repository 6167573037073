import React from 'react';
import {
  LogoContainer,
  LogoImage,
  lightLogoImage,
  darkLogoImage
} from './styles';

const Logo = ({ showNav, isBgDark = true }) => {
    return (
      <div>
        <LogoContainer>
          <LogoImage src={ !isBgDark || showNav ? darkLogoImage : lightLogoImage } showNav={ showNav } alt="Sophinex Investment LLC logo" />
        </LogoContainer>
      </div>
    );
  };

export default Logo; 
