import React from 'react';
import { isEmpty } from 'lodash';

import { Button, RouteButton } from '../ButtonElements';
import NavigationLink from '../NavigationLink';
import Section from '../Section';
import {
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  BtnWrap,
  ImgWrap,
  CustomSectionTitle
} from './styles';
import { TextSize, NavLinkStyleTypes } from '../../types';
import BodyText from '../BodyText';
import Image from '../Image';

const InfoSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  headerFloat,
  description,
  buttonLabel,
  img,
  imgSourceHref,
  imgSourceTitle,
  imgSourceIntro,
  imgSourceColor = 'on-light',
  imgSourceLocation,
  imgSourceAlignment,
  imgSourceVerticalOffset,
  imgSourceShowIcon,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2,
  transparent = true,
  inverted,
  routeTo,
  to,
  isExternalLink = false,
  displayBackground = false,
  sectionHeight = '860px',
  className,
  children
}) => {
  return (
    <>
      <Section id={id} lightBg={lightBg} transparent={transparent} height={sectionHeight} className={ className }>
        <InfoRow imgStart={imgStart} hasImage={!!img} className="InfoRow">
          <Column1 displayBackground={displayBackground}>
            {
              !isEmpty(topLine) || !isEmpty(headline) || !isEmpty(description)
                ? <TextWrapper>
                    <CustomSectionTitle topText={ topLine } headerText={ headline } textSize={ TextSize.large } darkText={ darkText } headerFloat={headerFloat}>
                      <BodyText text={ description } alignment={ 'justify' } />
                    </CustomSectionTitle>
                    <BtnWrap>
                      {
                        to
                          ? <Button
                              to={ to || 'home' }
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact='true'
                              offset={-80}
                              primary={primary ? 1 : 0}
                              dark={dark ? 1 : 0}
                              dark2={dark2 ? 1 : 0}
                              inverted={inverted ? 1 : 0}
                            >
                              {buttonLabel}
                            </Button>
                          : !isExternalLink 
                            ? <RouteButton
                                to={ routeTo || '/' }
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                inverted={inverted ? 1 : 0}
                              >
                                {buttonLabel}
                              </RouteButton>
                            : <NavigationLink
                                title={ buttonLabel }
                                to={ routeTo } 
                                style={ NavLinkStyleTypes.button } 
                                isExternalLink={ true } 
                                isScrollNav={ false } 
                                className= { inverted ? 'inverted' : '' } 
                                primary
                                dark />
                      }
                    </BtnWrap>
                  </TextWrapper>
              : children 
            }
          </Column1>
          <Column2 hasImage={!!img}>
          {
            (!!img) 
              ? <ImgWrap>
                  <Image 
                    img={ img } 
                    alt={ alt }
                    imgSourceColor={ imgSourceColor }
                    imgSourceHref={ imgSourceHref }
                    imgSourceTitle={ imgSourceTitle }
                    imgSourceIntro={ imgSourceIntro }
                    imgSourceAlignment={ imgSourceAlignment }
                    imgSourceLocation={ imgSourceLocation }
                    imgSourceVerticalOffset={ imgSourceVerticalOffset }
                    imgSourceShowIcon={ imgSourceShowIcon }
                  />
                </ImgWrap> 
              : null
          }
          </Column2>
        </InfoRow>
      </Section>
    </>
  );
};

export default InfoSection;
