import React from 'react';
import {
  ImageWrapper, ImageContent
} from './styles';
import { ContentFloat } from '../../types';

import ImageSourceInfo from '../ImageSourceInfo';

const Image = (props) => {
  const {
    align = ContentFloat.center,
    img,
    alt,
    bgImage,
    imgSourceHref,
    imgSourceTitle,
    imgSourceIntro,
    imgSourceColor,
    imgSourceLocation,
    imgSourceAlignment,
    imgSourceVerticalOffset,
    imgSourceShowIcon,
    maxHeight,
    maxWidth
  } = props;

  return (
    <ImageWrapper {  ...props }>
      <ImageContent src={ `${require(`../../images/${img}`)}` } maxHeight={ maxHeight } maxWidth={ maxWidth } alt={ alt } />
      <ImageSourceInfo 
          href={ imgSourceHref } 
          title={ imgSourceTitle } 
          intro={ imgSourceIntro } 
          color={ imgSourceColor } 
          location={ imgSourceLocation } 
          alignment={ imgSourceAlignment }
          verticalOffset={ imgSourceVerticalOffset }
          showIcon={ imgSourceShowIcon } />
    </ImageWrapper>
  );
};

export default Image;
