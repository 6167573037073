import React from 'react';
//import Image from 'react-bootstrap/Image'
import Avatar, { facebook } from 'react-avatar';
import { AvatarContainer } from './styles';

const AvatarComponent = ({ image }) => {
    //const imageUrl = url(`${require(`../../images/${imageName}`)}`);
    //const imageUrl = `../../images/${image}`;
    //console.debug(imageUrl);
    return (
        <AvatarContainer>
            <Avatar size="60" facebook src="https://graph.facebook.com/100008343750912/picture?width=200&height=200" round={true} />
        </AvatarContainer>
    );
};

export default AvatarComponent;
