import React from 'react';
import Section from '../Section';
import InfoBlock from '../InfoBlock';
import {
  BlockWrapper
} from './styles';

const BlockSection = (props) => {

  const {
      lightBg,
      imgStart,
      topLine,
      headerFloat,
      lightText,
      headline,
      description,
      buttonLabel,
      img,
      alt,
      id,
      darkText,
      dark,
      dark2,
      transparent,
      inverted,
      routeTo,
      to,
      textSize,
      highlightContent,
      sectionHeight = '860px',
      className,
      style
    } = props;

  return (
    <Section id={id} lightBg={lightBg} transparent={transparent} height={sectionHeight} className={ className } style={ style }>
      <BlockWrapper>
        <InfoBlock {...props}></InfoBlock>
      </BlockWrapper>
    </Section>
  );
};

export default BlockSection;
