import React from 'react';
import GeneralLayout from '../layouts/GeneralLayout';
import { LayoutTypes } from '../types';
import ContactUs from '../components/ContactUs';
import { getMainMenuItems } from '../utils/nav-menu';

const ContactUsPage = () => {

  const navOptions = {
    ...getMainMenuItems(LayoutTypes.main),
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout {...navOptions}>
      <ContactUs></ContactUs>
    </GeneralLayout>
  );
}

export default ContactUsPage;
