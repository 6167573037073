import styled from 'styled-components';

import SectionTitle from '../SectionTitle';

export const CustomSectionTitle = styled(SectionTitle)`
  padding-right: 0 important;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  /* Must use '\' css class \'' */
  grid-template-areas: ${({ imgStart }) => imgStart ? "'col2 col1'" : "'col1 col2'" };

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart, hasImage }) =>
      !hasImage ? `'col1'` : (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)
    };
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: ${({ displayBackground }) => displayBackground ? "50px 55px" : "0 15px" };
  grid-area: col1;
  border-radius: ${({ displayBackground }) => displayBackground ? "20px" : "0" };
  background-color: ${({ displayBackground }) => displayBackground ? "rgba(0,0,0,0.4)" : "none" };

  @media screen and (max-width: 600px) {
    padding: ${({ displayBackground }) => displayBackground ? "20px 20px" : "0 15px" };
}
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    display: ${({ hasImage }) => hasImage ? 'cell' : 'none' };
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 10px;

  p {
    font-size: 1.1rem;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
`;

export const ImgWrap = styled.div`
  max-width: 650px;
  height: 100%;
`;
