import React from 'react';
import { CardWrapper, CardImage, CardContent } from './styles';
import { ContentFloat, TextSize, TextTypes, SpacerSize, HeaderTextTypes } from '../../types';
import HeaderText from '../HeaderText';
import BodyText from '../BodyText';
import Spacer from '../Spacer';

const CardBlock = (props) => {

  const {
    children,
    float = ContentFloat.left,
    image,
    imageAlt,
    title,
    subTitle,
    text,
    titleSize = HeaderTextTypes.h5,
    subTitleSize = HeaderTextTypes.h7,
    textSize = TextSize.middle,
    color = '#000000',
    bgColor = '#ffffff',
    borderColor = '#000000',
    maxWidth,
    minHeight
  } = props;

  return (
    <CardWrapper 
      hasImage={ !!image } 
      maxWidth={ maxWidth } 
      minHeight={ minHeight } 
      color={ color } 
      bgColor={ bgColor} 
      borderColor ={ borderColor } 
      className='card-shadow'
    >
      <CardImage image={ image } imageAlt={ imageAlt }></CardImage>
      <CardContent float={ float }>
        <HeaderText text={ subTitle } type={ subTitleSize } />
        <HeaderText text={ title } type={ titleSize } />
        <Spacer size={ SpacerSize.xs} />
        <BodyText size={ textSize } type={ TextTypes.div }>
          { text || children }
        </BodyText>
      </CardContent>
    </CardWrapper>
  );
};

export default CardBlock;
