import styled from 'styled-components';
import { InfoBlockBgLinePosition, ContentFloat } from '../../types';
import { respondTo } from '../mixins/responsive';

const getGridColumns = (cols, float) => {
    const t = float === 'right' ? -1 : 1;
    return `${cols[0] * t} / ${cols[1] * t}`;
}
  
const arrayToGridPos = (arr) => {
    return `${arr[0]} / ${arr[1]}`;
}
  
const getShapeGridCols = (posType = InfoBlockBgLinePosition.none, float = ContentFloat.left, size = 1500) => {
    switch (posType) {
        case InfoBlockBgLinePosition.side:
            return getGridColumns([5, 7], float);
        case InfoBlockBgLinePosition.middle:
            return getGridColumns([3, 5], float);
        case InfoBlockBgLinePosition.half:
            return getGridColumns([-1, -4], float);
        case InfoBlockBgLinePosition.sideBottom:
            return getGridColumns([5, 9], float);
        case InfoBlockBgLinePosition.top:
        case InfoBlockBgLinePosition.center:
        case InfoBlockBgLinePosition.bottom:
            return getGridColumns([1, 7], float);
        default:
            return getGridColumns([1, 1], float);
    }
}
  
const getShapeGridRows = (posType = InfoBlockBgLinePosition.none, float = ContentFloat.left, size = 1500) => {
    switch (posType) {
        case InfoBlockBgLinePosition.side:
            return arrayToGridPos([1, 7]);
        case InfoBlockBgLinePosition.middle:
            return arrayToGridPos([1, 7]);
        case InfoBlockBgLinePosition.half:
            return arrayToGridPos([1, 7]);
        case InfoBlockBgLinePosition.sideBottom:
            return arrayToGridPos(size <= 768 ? [4, 9] : [3, 9]);
        case InfoBlockBgLinePosition.top:
            return arrayToGridPos([1, 3]);
        case InfoBlockBgLinePosition.center:
            return arrayToGridPos([3, 5]);
        case InfoBlockBgLinePosition.bottom:
            return arrayToGridPos([5, 7]);
        default:
            return arrayToGridPos([1, 1]);
    }
}

export const BgShapeBlockWrapper = styled.div`
    padding: ${({ noGap }) => !!noGap ? '0' : '24px 0'};
    position: relative;
    min-height: 50px;
    width: 100%;
    height: ${({ fullHeight = false }) => fullHeight ? '100%' : 'auto' };
`;

export const BgShapeContent = styled.div`
    display: ${({ posType }) => posType === InfoBlockBgLinePosition.none ? 'none' : 'grid'};
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    z-index: ${({ base_z_index = 0 }) => base_z_index + 1 };
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

export const BgShape = styled.div`
  background-color: ${({ color }) => color ? color : "rgba(0, 0, 0, .03)" } ;
  height: 100%;
  width: 100%;
  grid-column: ${({ posType, float, hasImage }) => getShapeGridCols(posType, float, hasImage) };
  grid-row: ${({ posType, float }) => getShapeGridRows(posType, float, 1500) };
  z-index: ${({ base_z_index = 0 }) => base_z_index + 2 };

  @media screen and (max-width: 768px) {
    grid-column: ${({ posType, float, hasImage }) => getShapeGridCols(posType, float, 768, hasImage) };
    grid-row: ${({ posType, float }) => getShapeGridRows(posType, float, 768) };
  }
`;

export const BgShapeBlockContent = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    z-index: ${({ base_z_index = 0 }) => base_z_index + 3 };

    ${({ posType, float, addContentSideOffset }) => respondTo.xxs.standard`
        padding-right: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.left ? '34px' : '0' };
        padding-left: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.right ? '34px' : '0' };
    `};

    ${({ posType, float, addContentSideOffset }) => respondTo.xs.standard`
        padding-right: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.left ? '48px' : '0' };
        padding-left: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.right ? '48px' : '0' };
    `};

    ${({ posType, float, addContentSideOffset }) => respondTo.sm.standard`
        padding-right: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.left ? '64px' : '0' };
        padding-left: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.right ? '64px' : '0' };
    `};

    ${({ posType, float, addContentSideOffset }) => respondTo.md.standard`
        padding-right: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.left ? '80px' : '0' };
        padding-left: ${ !!addContentSideOffset && posType !== InfoBlockBgLinePosition.middle && posType !== InfoBlockBgLinePosition.none && float === ContentFloat.right ? '80px' : '0' };
    `};
`;