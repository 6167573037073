import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { NavigationContextProvider } from './context/NavigationContext';
import { history } from './helpers/history';

import HomePage from './pages';
import ContactUsPage from './pages/contactus';
import PrivacyPolicyPage from './pages/privacy-policy';
import CookiePolicyPage from './pages/cookie-policy';
import TermsOfUsePage from './pages/terms-of-use';
import CaNoticeAtCollectionPage from './pages/california-notice-at-collection';
import SigninPage from './pages/signin';
import Page404 from './pages/404';
import ToastMessage from './components/Toast';
import TermsOfServicePage from './pages/terms-of-service';
import './App.scss';
// import ReactGA from 'react-ga';
import WithPageTracker from './components/WithGATraker';

// ReactGA.initialize(process.env.REACT_APP_GA_KEY, { debug: true });

function App() {
  return (
    <NavigationContextProvider>
      <Router history={ history }>
        <ToastMessage />
        <Switch>
          <Route exact path='/' component={WithPageTracker(HomePage)} />
          <Route exact path='/contact-us' component={WithPageTracker(ContactUsPage)} />
          <Route exact path='/privacy-policy' component={WithPageTracker(PrivacyPolicyPage)} />
          <Route exact path='/cookie-policy' component={WithPageTracker(CookiePolicyPage)} />
          <Route exact path='/terms-of-use' component={WithPageTracker(TermsOfUsePage)} />
          <Route exact path='/terms-of-service' component={WithPageTracker(TermsOfServicePage)} />
          <Route exact path='/california-notice-at-collection' component={WithPageTracker(CaNoticeAtCollectionPage)} />
          <Route exact path='/signin' component={WithPageTracker(SigninPage)} />
          <Route component={WithPageTracker(Page404)} />
        </Switch>
      </Router>
    </NavigationContextProvider>
  );
}

export default App;
