import React, { Component } from 'react';
//import ReactGA from 'react-ga';

const WithPageTracker = (WrappedComponent, options = {}) => {

  const trackPage = (page) => {
    /*ReactGA.set({
      page,
      ...options
    });*/
    // ReactGA.pageview(page);
  };

  const HOC = class extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
      const nextPage = nextProps?.location.pathname + nextProps?.location.hash;
      if (nextPage !== prevState?.currentPage) {
        trackPage(nextPage);
        return ({ currentPage: nextPage }); // <- this is setState equivalent
      }
      return null;
    }

    state = {
      currentPage: this.props?.location.pathname + this.props?.location.hash
    }

    componentDidMount() {
      trackPage(this.state.currentPage);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}

export default WithPageTracker;