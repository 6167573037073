import styled from 'styled-components';
import { ContentFloat, InfoBlockBgLinePosition, TextSize } from '../../types';
import SectionTitle from '../SectionTitle';

const getGridColumns = (cols, float) => {
  const t = float === 'right' ? -1 : 1;
  return `${cols[0] * t} / ${cols[1] * t}`;
}

const arrayToGridPos = (arr) => {
  return `${arr[0]} / ${arr[1]}`;
}

const getShapeGridCols = (posType = InfoBlockBgLinePosition.none, float = ContentFloat.left, size = 1500, hasImage = false) => {
  switch (posType) {
    case InfoBlockBgLinePosition.side:
      return getGridColumns([6, 7], float);
    case InfoBlockBgLinePosition.middle:
      return getGridColumns([3, 5], float);
    case InfoBlockBgLinePosition.half:
      return getGridColumns(float === ContentFloat.right ? [-1, -4] : [-1, -4], float);
    case InfoBlockBgLinePosition.sideBottom:
      if (size <= 768 && hasImage) {
        return getGridColumns([1, 7], float);
      } else {
        return getGridColumns([3, 7], float);
      }
    default:
      return getGridColumns([1, 1], float);
  }
}

const getShapeGridRows = (posType = InfoBlockBgLinePosition.none, float = ContentFloat.left, size = 1500, hasImage = false) => {
  switch (posType) {
    case InfoBlockBgLinePosition.side:
      return arrayToGridPos(size <= 768 && hasImage ? [5, 9] : [1, 5]);
    case InfoBlockBgLinePosition.middle:
      return arrayToGridPos(size <= 768 && hasImage ? [5, 9] : [1, 5]);
    case InfoBlockBgLinePosition.half:
      return arrayToGridPos([1, 5]);
    case InfoBlockBgLinePosition.sideBottom:
      return arrayToGridPos(size <= 768 && hasImage ? [7, 9] : [1, 5]);
    default:
      return arrayToGridPos([1, 1]);
  }
}

const getImageGridCols = (float, size) => {
  return getGridColumns(size <= 768 ? [1, 7] : [-1, -4], float);
}

const getImageGridRows = (size) => {
  return arrayToGridPos(size <= 1100 ? [5, 9] : [1, 5]);
}

const getContentGridCols = (float, size, hasImage) => {
  if (size <= 768) {
    return getGridColumns(hasImage ? [1, 7] : [1, 6], float);
  }
  if (float === ContentFloat.right) {
    return getGridColumns(hasImage ? [1, 4] : [1, 6], float);
  }
  return getGridColumns(hasImage ? [1, 4] : [1, 6], float);
}

const getContentGridRows = () => {
  return arrayToGridPos([1, 5]);
}

export const CustomSectionTitle = styled(SectionTitle)`
  padding-right: 0 important;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: ${({ float }) => float === ContentFloat.right ? "flex-end" : "flex-start" };
  margin: 30px 0 30px 0;
`;

export const BlockWrapper = styled.div`
  margin: ${({ margin }) => margin ? margin : 0 };
  position: relative;
`;

export const BlockBody = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 40px;

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ float, hasImage }) =>
      !hasImage ? `'col1'` : (float ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)
    };
  }
`;

export const BlockBgShape = styled.div`
  background-color: ${({ color }) => color ? color : "rgba(0, 0, 0, .03)" } ;
  height: 100%;
  width: 100%;
  grid-column: ${({ posType, float, hasImage }) => getShapeGridCols(posType, float, hasImage) };
  grid-row: ${({ posType, float, hasImage }) => getShapeGridRows(posType, float, 1500, hasImage) };
  z-index: 0;

  @media screen and (max-width: 768px) {
    grid-column: ${({ posType, float, hasImage }) => getShapeGridCols(posType, float, 768, hasImage) };
    grid-row: ${({ posType, float, hasImage }) => getShapeGridRows(posType, float, 768, hasImage) };
  }
`;

export const BlockImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  grid-column: ${({ float }) => getImageGridCols(float) };
  grid-row: ${() => getImageGridRows(1360) };
  background-image: ${({ bgImage }) => bgImage ? `url(${require(`../../images/${bgImage}`)})` : 'unset'};
  background-size: cover;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    grid-column: ${({ float }) => getImageGridCols(float, 768) };
    grid-row: ${() => getImageGridRows(768) };
  }
`;

export const BlockImage = styled.img`
  /*max-width: 90%;
  max-height: 90%;*/

  @media screen and (max-width: 1360px) {
    max-width: 90%;
    max-height: 90%;
  }

  @media screen and (max-width: 768px) {
    max-width: 80%;
    max-height: 80%;
  }
`;

export const BlockContent = styled.div`
  grid-column: ${({ float, hasImage }) => getContentGridCols(float, 1360, hasImage) };
  grid-row: ${() => getContentGridRows()};
  width: 100%;
  min-height: 150px;
  z-index: 2;

  margin-top: 0;
  background-color: ${({ highlightContent }) => !!highlightContent ? 'rgba(255,255,255,0.35)' : 'transparent' };
  padding: ${({ highlightContent }) => !!highlightContent ? '32px 32px 8px 32px' : '0' };
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    grid-column: ${({ float, hasImage }) => getContentGridCols(float, 768, hasImage) };
  }

  @media screen and (max-width: 1024px) {
    /*margin-top: 64px;*/
  }
`;

