import React from 'react';
import {
  FaFacebook,
  //FaInstagram,
  FaYoutube,
  //FaTwitter,
  FaLinkedin,
  FaFax,
  FaPhone,
  FaEnvelope,
  //FaHome
} from 'react-icons/fa';
//import { animateScroll as scroll } from 'react-scroll';

import { NavLinkStyleTypes } from '../../types';
import NavigationLinkWithContext from '../NavigationLinkWithContext';
import { 
  COMPANY_FULL_NAME, 
  COPYRIGHT, 
  COMPANY_EMAIL,
  COMPANY_PHONE, 
  COMPANY_FAX,
  SERVICE_STATES,
  COMPANY_DESCRIPTION, 
  /*COMPANY_ADDRESS, 
  COMPANY_ADDRESS_2, 
  COMPANY_CITY, 
  COMPANY_STATE, 
  COMPANY_POSTAL_CODE*/
 } from '../../shared/globals';

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterText,
  ContactInfo,
  ContactInfoIcon,
  ContactInfoText,
  //SocialLogo,
  WebsiteInfoContainer,
  WebsiteInfo,
  WebsiteRights,
  WebsiteDev,
  WebsitePolicy,
  SocialMedia,
  SocialMediaWrap,
  SocialIcons,
  SocialIconLink
} from './styles';

import ImageSourceInfo from '../ImageSourceInfo';

const Footer = (props) => {

  /*const toggleHome = () => {
    scroll.scrollToTop();
  };*/

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper className='row'>
            <FooterLinkItems className='col-xs-12 col-md-8 col-lg-5'>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterText>
                { COMPANY_DESCRIPTION }
              </FooterText>
              <FooterLinkTitle>Areas We Service</FooterLinkTitle>
              <FooterText>
                US: { SERVICE_STATES }.
              </FooterText>
              <SocialMedia className="d-none">
                <SocialMediaWrap>
                  <SocialIcons>
                    <SocialIconLink href='/' target='_blank' aria-label='Linkedin'>
                      <FaLinkedin />
                    </SocialIconLink>
                    <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
                      <FaFacebook />
                    </SocialIconLink>              
                    <SocialIconLink
                      href='//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber'
                      target='_blank'
                      aria-label='Youtube'
                      rel='noopener noreferrer'
                      className='d-none'
                    >
                      <FaYoutube />
                    </SocialIconLink>
                  </SocialIcons>
                </SocialMediaWrap>
              </SocialMedia>
            </FooterLinkItems>

            <FooterLinkItems className='col-xs-6 col-md-4 col-lg-3'>
              <FooterLinkTitle>Contact Info</FooterLinkTitle>
              <ContactInfo>
                <ContactInfoIcon><FaPhone /></ContactInfoIcon>
                <ContactInfoText>{ COMPANY_PHONE } (toll-free)</ContactInfoText>
              </ContactInfo>
              <ContactInfo>
                <ContactInfoIcon><FaFax /></ContactInfoIcon>
                <ContactInfoText>{ COMPANY_FAX }</ContactInfoText>
              </ContactInfo>
              <ContactInfo>
                <ContactInfoIcon><FaEnvelope /></ContactInfoIcon>
                <ContactInfoText>{ COMPANY_EMAIL }</ContactInfoText>
              </ContactInfo>
            </FooterLinkItems>

            <FooterLinkItems className='col-xs-6 col-md-8 col-lg-2'>
              <FooterLinkTitle>Quick Links</FooterLinkTitle>
              <NavigationLinkWithContext title={ 'Home' } path={ '/' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'Home' } />
              <NavigationLinkWithContext title={ 'About Us' } path={ '/' } hash={ 'about' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'About Us' } />
              <NavigationLinkWithContext title={ 'Our Services' } path={ '/' } hash={ 'services' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'Our Services' } />
              <NavigationLinkWithContext title={ 'Testimonials' } path={ '/' } hash={ 'testimonials' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'Testimonials' } />
              <NavigationLinkWithContext title={ 'Contact Us' } path={ '/contact-us' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'Contact Us' } />
            </FooterLinkItems>

            <FooterLinkItems className='col-xs-6 col-md-4 col-lg-2'>
              <FooterLinkTitle>Extra Links</FooterLinkTitle>
              <NavigationLinkWithContext title={ 'Careers' } path={ '/careers' } isExternalLink={ true } style={ NavLinkStyleTypes.footer } alt={ 'Careers' } />
              <NavigationLinkWithContext title={ 'Investors' } path={ '/investors' } isExternalLink={ true } style={ NavLinkStyleTypes.footer } alt={ 'Investors' } />
              <NavigationLinkWithContext title={ 'Terms of Service' } path={ '/terms-of-service' } isExternalLink={ true } className="d-none" style={ NavLinkStyleTypes.footer } alt={ 'Terms of Service' } />
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>

        <WebsiteInfoContainer>
          <WebsiteInfo className='row'>
            <WebsiteRights className='col-xs-12 col-md-6 col-lg-4'>{ COPYRIGHT }</WebsiteRights>
            <WebsiteDev className='d-none'>
              Designed &amp; Developed by <NavigationLinkWithContext title={ 'Patrick J. Edwards' } path={ 'https://creekcross.com' } isExternalLink={ true } style={ NavLinkStyleTypes.footer } alt={ 'Patrick J. Edwards' } />
            </WebsiteDev>
            <WebsitePolicy className='col-xs-12 col-md-6 col-lg-8 d-none-'>
              <NavigationLinkWithContext title={ 'Privacy Policy' } path={ '/privacy-policy' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'Privacy Policy' } />&nbsp;|&nbsp; 
              <NavigationLinkWithContext title={ 'Cookie Policy' } path={ '/cookie-policy' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'Cookie Policy' } />&nbsp;|&nbsp; 
              <NavigationLinkWithContext title={ 'Terms of Use' } path={ '/terms-of-use' } isExternalLink={ false } style={ NavLinkStyleTypes.footer } alt={ 'Terms of Use' } />
            </WebsitePolicy>
          </WebsiteInfo>
        </WebsiteInfoContainer>
      </FooterWrap>
      <ImageSourceInfo 
          href='https://www.freepik.com/free-vector/wave-white-background-modern-gradient_31179995.htm#page=5&query=background&position=49&from_view=search&track=sph'
          title='Image by logturnal' 
          intro='image by logturnal'
          color='on-dark'
          location='inline'
          alignment='bottom-right'
          verticalOffset={-8}
          showIcon={false} />
    </FooterContainer>
  );
};

export default Footer;
