import React from 'react';
import { ContainerWithBackgroundWrapper, ContainerWithBackgroundContentWrapper } from './styles';
import { ViewHeightVariant } from '../../types';

const ContainerWithBackground = (props) => {

  const {
    children,
    heightVariant = ViewHeightVariant.auto,
    baseZIndex = 0
  } = props;

  return (
    <ContainerWithBackgroundWrapper { ... props } baseZIndex={ baseZIndex }>
      <ContainerWithBackgroundContentWrapper heightVariant={ heightVariant } baseZIndex={ baseZIndex }>
        { children }
      </ContainerWithBackgroundContentWrapper>
    </ContainerWithBackgroundWrapper>
  );
};

export default ContainerWithBackground;
