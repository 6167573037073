import styled from 'styled-components';
import { ContentFloat, TextSize } from '../../types';
import { responsive } from '../mixins/responsive';

const getTopTextFontSize = ( textSize ) => {
  return `${ textSize === TextSize.small ? 12 : textSize === TextSize.middle ? 14 : 16 }px`; 
}

const getHeaderTextFontSize = ( textSize ) => {
  return `${ textSize === TextSize.small ? 22 : textSize === TextSize.middle ? 36 : 48 }px`; 
}

const getHeaderTextPaddingLeft = ( textSize ) => {
  return `${ textSize === TextSize.small ? 15 : 20 }px`; 
}

const getContentTextFontSize = ( textSize ) => {
  return `${ textSize === TextSize.small ? 16 : textSize === TextSize.middle ? 18 : 20 }px`; 
}

export const Container = styled.div`
  margin-bottom: 20px;
  padding: ${({ topPadding }) => topPadding || 0 } ${({ rightPadding }) => rightPadding || 0 } ${({ bottomPadding }) => bottomPadding || 0 } ${({ leftPadding }) => leftPadding || 0 };
  color: ${({ darkText }) => (darkText ? '#373737': '#f7f8fa')};
  position: relative;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  margin-right: auto;
  margin-left: ${({displayLine}) => displayLine ? '3px' : 0};
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: ${({ float} ) => float === ContentFloat.right ? 'flex-end' : float === ContentFloat.center ? 'center' : 'flex-start'};
  z-index: 1;
  height: ${({ height} ) => height || 'auto'};
  width: 100%;
  max-width: 1360px;
`;

export const TopLine = styled.div`
  letter-spacing: 0.7px;
  margin-bottom: 16px;
  font-weight: 700 !important;

  ${responsive.mobile.standard`
    margin-bottom: 12px;
  `};

  ${responsive.small_mobile.standard`
    margin-bottom: 10px;
  `};

  ${responsive.xs_mobile.standard`
    margin-bottom: 8px;
  `};
`;

export const Header = styled.div`
  margin-left: ${({displayLine, float}) => displayLine && float === ContentFloat.left ? '3px' : 0};
  margin-right: ${({displayLine, float}) => displayLine && float === ContentFloat.right ? '3px' : 0};
  padding-left: ${({textSize, displayLine, float}) => displayLine && float === ContentFloat.left ? getHeaderTextPaddingLeft(textSize) : 0};
  padding-right: ${({textSize, displayLine, float}) => displayLine && float === ContentFloat.right ? getHeaderTextPaddingLeft(textSize) : 0};
  text-align: ${({float}) => float ? float : ContentFloat.left};
  ${({displayLine, float}) => displayLine ? (float == ContentFloat.right ? 'border-right: #dd4132 4px solid;' : 'border-left: #dd4132 4px solid;') : ''};
`;

export const ContentWrapper = styled.div`
  font-size: 1.1rem;
  text-align: justify;
`;

export const HeaderHorizLine = styled.div`
  width: 70px;
  height: 3px;
  background-color: red;
  content: '';
  margin: 24px auto 24px;
`;

export const HeaderHorizLineWrapper = styled.div`
  display: 'flex';
`;