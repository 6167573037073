import React from 'react';

import AvatarComponent from '../../Avatar';
import {
    ItemContainer,
    ItemHeader,
    ItemHeaderSidebar,
    ItemHeaderMain,
    ItemHeaderTitle,
    ItemHeaderSubTitle,
    ItemMain,
    ItemMainBody
} from './styles';

const TestimonialItemComponent = (props) => {

    const {
        id,
        fullName,
        body,
        image,
        date,
        alt
    } = props;

    return (
        <ItemContainer { ...props }>
            <ItemHeader>
                <ItemHeaderSidebar>
                    <AvatarComponent image={image || ''}></AvatarComponent>
                </ItemHeaderSidebar>
                <ItemHeaderMain>
                    <ItemHeaderTitle>{ fullName }</ItemHeaderTitle>
                    <ItemHeaderSubTitle>{ date }</ItemHeaderSubTitle>
                </ItemHeaderMain>
            </ItemHeader>
            <ItemMain>
                <ItemMainBody>{ body }</ItemMainBody>
            </ItemMain>
        </ItemContainer>
    );
};

export default TestimonialItemComponent;
