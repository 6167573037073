import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';

export const FooterContainer = styled.footer`
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .8)), to(rgba(0, 0, 0, .6))), url(${require(`../../images/31179995_10.jpg`)}) no-repeat;
  background: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .6)), url(${require(`../../images/31179995_10.jpg`)}) no-repeat;
  background-position-x: 0%, 0%;
  background-position-y: bottom 400px;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-size: cover;
  background-attachment: fixed;
`;

export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1360px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  width: 100%;
  padding: 16px;
`;

export const FooterLinksWrapper = styled.div`
  width: 100%;
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  text-align: left;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 16px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
  border-left: #df423e 4px solid;
  padding-left: 10px;
  text-transform: uppercase;
`;

export const FooterText = styled.p`
  font-size: 14px;
  text-align: justify;
`;

export const ContactInfo = styled.div`
  margin-bottom: 0.5rem;
  font-size: .87rem;
`;
export const ContactInfoIcon = styled.i`
  padding-right: 8px;
`;
export const ContactInfoText = styled.span``;

export const SocialMedia = styled.section`
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SocialLogo = styled(LinkR)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  text-decoration: none !important;
`;

export const WebsiteInfoContainer = styled.div`
  margin-top: 0px;
  padding: 0 32px 16px 32px;
  width: 100%
`

export const WebsiteInfo = styled.div`
  color: #fff;
  width: 100%;
`;

export const WebsiteRights = styled.div`
  font-size: .9rem;
  color: #fff;
  margin-bottom: 16px;
`;

export const WebsiteDev = styled.div`
  margin-top: -10px;
  color: #fff;
  margin-bottom: 16px;
`;

export const WebsitePolicy = styled.div`
  flex-grow: 1;
  text-align: right;
  font-size: .9rem;
  color: #fff;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin-right: .7rem;
  pointer-events: none;

  &:hover {
    color: #df423e;
    transition: 0.3s ease-out;
  }
`;
