import { LayoutTypes } from "../types";

export const getMainMenuItems = (layoutType = LayoutTypes.home) => {

  const baseUrl = '/'; //process.env.REACT_APP_SOPHINEX_DOMAIN_BASE_URL;

  return {
    baseUrl,
    menuItems: [
      {
        title: 'HOME',
        path: baseUrl,
        hash: 'home'
      },
      {
        title: 'ABOUT US',
        path: baseUrl,
        hash: 'about'
      },
      {
        title: 'OUR SERVICES',
        path: baseUrl,
        hash: 'services'
      },
      {
        title: 'TESTIMONIALS',
        path: baseUrl,
        hash: 'testimonials'
      },
      {
        title: 'CONTACT US',
        path: '/contact-us',
        isScrollNav: false 
      }
    ],
    /*menuButton: {
      title: 'Sign Up',
      path: `${baseUrl}signin`,
      isExternalLink: true,
      className: 'd-none'
    },*/
    layoutType
  };
};