import React from 'react';

import { ListContainer, ListWrapper, ListSectionTitle } from './styles';
import { TextSize, ContentFloat } from '../../types';
import TestimonialItemComponent from './Item';
import { Testimonials } from './data';

const TestimonialListComponent = (props) => {
    return (
        <ListContainer { ...props }>
            <ListSectionTitle 
                topText={ 'Testimonials' } 
                headerText={ props?.headerText || 'What Clients Have Said' } 
                textSize={ TextSize.large } 
                headerFloat={ ContentFloat.center} 
                isVertical={ true }
            />
            <ListWrapper>
                {Testimonials.map((item, key) => (
                    <TestimonialItemComponent { ...item} key={key} className='card-shadow'></TestimonialItemComponent>
                ))}
            </ListWrapper>
        </ListContainer>
    );
};

export default TestimonialListComponent;
