import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';
import { ContentFloat } from '../../types';

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ bgImage }) => bgImage ? `url(${require(`../../images/${bgImage}`)})` : 'unset'};
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: inherit;
`;

export const ImageContent = styled.img`
  width: 100%;

  ${ respondTo.xxs.standard`
    max-width: 100%;
    max-height: 100%;
  `};

  ${ respondTo.xs.standard`
    max-width: 100%;
    max-height: 100%;
  `};

  ${({ maxWidth = 90, maxHeight = 90 }) => respondTo.md.standard`
    max-width: ${ maxWidth + 10 }%;
    max-height: ${ maxHeight + 10 }%;
  `};

  ${({ maxWidth = 90, maxHeight = 90 }) => respondTo.xxl.standard`
    max-width: ${ maxWidth }%;
    max-height: ${ maxHeight }%;
  `};
`;