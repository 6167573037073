export const page404SectionContent = {
    id: 'notfound',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Oops',
    headline: 'Page not found',
    description: 'Sorry, the page you requested could not be found.',
    buttonLabel: 'Go Home',
    imgStart: false,
    img: '404-error-with-a-tired-person-pana-red-online.svg',
    imgSourceHref: 'https://storyset.com/online',
    imgSourceTitle: 'Online illustrations by Storyset',
    imgSourceIntro: 'illustration by storyset',
    imgSourceColor: 'on-light',
    imgSourceLocation: 'relative',
    imgSourceAlignment: 'bottom-center',
    imgSourceVerticalOffset: 0,
    imgSourceShowIcon: false,
    alt: 'Page 404',
    dark: true,
    primary: true,
    darkText: true,
    routeTo: '/'
  };