import styled from 'styled-components';
import { responsive } from '../mixins/responsive';
import { text } from '../mixins/text';
import { TextSize } from '../../types';

const getTextSize = (size = TextSize.middle) => {
    switch (size) {
        case TextSize.large:
            return {
                desktop: {
                    fontSize: '1.375rem'
                },
                tablet: {
                    fontSize: '1.125rem'
                },
                small_tablet: {
                    fontSize: '1.125rem'
                },
                mobile: {
                    fontSize: '1.125rem'
                },
                xs_mobile: {
                    fontSize: '0.938rem',
                    lineHeight: '1.25rem'
                } 
            };
        case TextSize.small:
            return {
                desktop: {
                    fontSize: '0.938rem'
                },
                tablet: {
                    fontSize: '0.875rem'
                },
                small_tablet: {
                    fontSize: '0.875rem'
                },
                mobile: {
                    fontSize: '0.875rem'
                },
                xs_mobile: {
                    fontSize: '0.75rem',
                    lineHeight: '0.875rem'
                } 
            };
        case TextSize.xs:
            return {
                desktop: {
                    fontSize: '0.813rem'
                },
                tablet: {
                    fontSize: '0.813rem'
                },
                small_tablet: {
                    fontSize: '0.813rem'
                },
                mobile: {
                    fontSize: '0.813rem'
                },
                xs_mobile: {
                    fontSize: '0.688rem',
                    lineHeight: '0.813rem'
                } 
            };
        default:
            return {
                desktop: {
                    fontSize: '1.125rem'
                },
                tablet: {
                    fontSize: '1.0625rem'
                },
                small_tablet: {
                    fontSize: '1.0625rem'
                },
                mobile: {
                    fontSize: '1.0625rem'
                },
                xs_mobile: {
                    fontSize: '0.875rem',
                    lineHeight: '1.065rem'
                }
            };
    }
}

export const SPAN = styled.span`
    ${({ size, weight }) => text.text(getTextSize(size).desktop.fontSize, weight)};

    ${({ size, weight }) => responsive.tablet.standard`
        ${text.text(getTextSize(size).tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.small_tablet.standard`
        ${text.text(getTextSize(size).small_tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.mobile.standard`
        ${text.text(getTextSize(size).mobile.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.xs_mobile.standard`
        ${text.text(getTextSize(size).xs_mobile.fontSize, weight, getTextSize(size).xs_mobile.lineHeight)};
    `};

    color: ${({ color }) => color} !important;
    text-transform: ${({ transform }) => transform} !important;
`;

export const P = styled.p`
    ${({ size, weight }) => text.text(getTextSize(size).desktop.fontSize, weight)};

    ${({ size, weight }) => responsive.tablet.standard`
        ${text.text(getTextSize(size).tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.small_tablet.standard`
        ${text.text(getTextSize(size).small_tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.mobile.standard`
        ${text.text(getTextSize(size).mobile.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.xs_mobile.standard`
        ${text.text(getTextSize(size).xs_mobile.fontSize, weight, getTextSize(size).xs_mobile.lineHeight)};
    `};

    color: ${({ color }) => color} !important;
    text-align: ${({ alignment }) => alignment} !important;
    text-transform: ${({ transform }) => transform} !important;
    margin-bottom: 0;
`;

export const DIV = styled.div`
    ${({ size, weight }) => text.text(getTextSize(size).desktop.fontSize, weight)};

    ${({ size, weight }) => responsive.tablet.standard`
        ${text.text(getTextSize(size).tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.small_tablet.standard`
        ${text.text(getTextSize(size).small_tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.mobile.standard`
        ${text.text(getTextSize(size).mobile.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.xs_mobile.standard`
        ${text.text(getTextSize(size).xs_mobile.fontSize, weight, getTextSize(size).xs_mobile.lineHeight)};
    `};

    color: ${({ color }) => color} !important;
    text-align: ${({ alignment }) => alignment} !important;
    text-transform: ${({ transform }) => transform} !important;
`;

export const LABEL = styled.label`
    ${({ size, weight }) => text.text(getTextSize(size).desktop.fontSize, weight)};

    ${({ size, weight }) => responsive.tablet.standard`
        ${text.text(getTextSize(size).tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.small_tablet.standard`
        ${text.text(getTextSize(size).small_tablet.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.mobile.standard`
        ${text.text(getTextSize(size).mobile.fontSize, weight)};
    `};

    ${({ size, weight }) => responsive.xs_mobile.standard`
        ${text.text(getTextSize(size).xs_mobile.fontSize, weight, getTextSize(size).xs_mobile.lineHeight)};
    `};

    color: ${({ color }) => color} !important;
    text-align: ${({ alignment }) => alignment} !important;
    text-transform: ${({ transform }) => transform} !important;
`;