import React from 'react';
import { SpacerConteiner } from './styles';
import { SpacerSize } from '../../types';

const Spacer = (props) => {

    const {
        size = SpacerSize.md
    } = props;

    return (
        <SpacerConteiner { ... props } size={ size } />
    );
}

export default Spacer;
