import styled from 'styled-components';
import { ViewHeightVariant } from '../../types';
import { hexToRgb } from '../../utils/colors';

const getBgColorString = (hexColor = 'transparent', transparency = 0.3) => {
  if (!hexColor || /^\s*$/.test(hexColor)) {
    return 'none';
  }
  if (hexColor === 'transparent') {
    return hexColor;
  }
  const arrRgb = hexToRgb(hexColor);

  return `rgba(${arrRgb[0]}, ${arrRgb[1]}, ${arrRgb[2]}, ${transparency})`;
}

export const ContainerWithBackgroundWrapper = styled.div`
  background-image: ${({ bgImage }) => bgImage ? `url(${require(`../../images/${bgImage}`)})` : 'unset'};
  background-size: cover;
  position: relative;
  width: 100%;
  height: ${({ heightVariant, height }) => 
    heightVariant === ViewHeightVariant.viewport 
      ? '100vh' 
      : heightVariant === ViewHeightVariant.full 
        ? '100%' 
        : heightVariant === ViewHeightVariant.custom 
          ? height 
          : 'auto'
  };
  overflow: hidden;
  z-index: ${({ baseZIndex = 0}) => baseZIndex};

  :before {
    content: '';
    background: ${({ bgColor, bgOverlayTransparency }) => getBgColorString(bgColor, bgOverlayTransparency)};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${({ baseZIndex = 0 }) => baseZIndex + 1};
  }
`;

export const ContainerWithBackgroundContentWrapper= styled.div`
  position: relative;
  height: ${({ heightVariant }) => 
    heightVariant === ViewHeightVariant.viewport || heightVariant === ViewHeightVariant.full || heightVariant === ViewHeightVariant.custom 
      ? '100%' 
      : 'auto'
  };
  z-index: ${({ baseZIndex = 0 }) => baseZIndex + 2};
`;