const validate = (value, rules) => {
  let isValid = true;
  let invalidRule = null;
  for (let rule in rules) {
    if (isValid) {
      switch (rule) {
        case 'required':
          isValid = isValid && requiredValidator(value);
          break;
        case 'email':
          isValid = isValid && emailValidator(value);
          break;
        case 'phone':
          isValid = isValid && phoneValidator(value);
          break;
        case 'minLength':
          isValid = isValid && minLengthValidator(value, rules[rule]);
          break;
        case 'maxLength':
          isValid = isValid && maxLengthValidator(value, rules[rule]);
          break;
        default: 
          isValid = true;
          break;
      }
      if (!isValid) {
        invalidRule = rule;
      }
    }
  }
  return !isValid ? {
    [invalidRule]: isValid
  } : true;
}

/**
 * Email validation
 * @param value
 * @return 
 */
 const emailValidator = value => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

/**
 * Phone validation
 * Valid formats:
 *    (123) 456-7890
 *    (123)456-7890
 *    123-456-7890
 *    123.456.7890
 *    1234567890
 *    +31636363634
 *    075-63546725 
 * @param value
 * @return 
 */
 const phoneValidator = value => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(String(value).toLowerCase());
}

/**
 * minLength Val
 * @param  value 
 * @param  minLength
 * @return          
 */
 const minLengthValidator = (value, minLength) => {
  return value.length >= minLength;
}

/**
 * maxLength Val
 * @param  value 
 * @param  maxLength
 * @return          
 */
 const maxLengthValidator = (value, maxLength) => {
  return value.length <= maxLength;
}

/**
 * Required Val
 * @param  value 
 * @return          
 */
const requiredValidator = (value) => {
  return value.length > 0 || /^\s*$/.test(value) === false;
}

export default validate;
