import React from 'react';
import Page_404 from '../components/404';
import ScrollToTop from '../components/ScrollToTop';

function Page404() {
  return (
    <>
      <ScrollToTop />
      <Page_404 />
    </>
  );
}

export default Page404;
