import styled from 'styled-components';
import { responsive } from '../mixins/responsive';

import darkLogoImageFile from '../../images/logo-llc.png';
import lightLogoImageFile from '../../images/logo-llc-light.png';

export const lightLogoImage = lightLogoImageFile;

export const darkLogoImage = darkLogoImageFile;

export const LogoContainer = styled.div`
  display: flex;
`;

export const LogoImage = styled.img`
  padding: 0 15px 0 0;
  height: ${({showNav}) => showNav ? '60px' : '70px'};

  ${(props) => responsive.mobile.standard`
    height: ${props.showNav ? '55px' : '65px'};
  `};

  ${(props) => responsive.small_mobile.standard`
    height: ${props.showNav ? '50px' : '55px'};
  `};

  ${(props) => responsive.xs_mobile.standard`
    height: ${props.showNav ? '45px' : '45px'};
  `};
`;
